import ApplicationSerializer from 'qonto/serializers/application';

export default class extends ApplicationSerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    delete json.approver_id;
    delete json.initiator_id;
    delete json.processed_at;
    delete json.created_at;
    delete json.reference;
    delete json.request_type;
    delete json.status;

    let { arrival, departure, ...rest } = json;
    let normalizedJourneyDetails = {
      arrival: {
        formatted_address: arrival.formattedAddress,
        google_place_id: arrival.googlePlaceId,
      },
      departure: {
        formatted_address: departure.formattedAddress,
        google_place_id: departure.googlePlaceId,
      },
    };

    return { ...normalizedJourneyDetails, ...rest };
  }

  normalize(model, hash, prop) {
    let { arrival, departure, mileage_calculation, ...rest } = hash;
    let normalizedJourneyDetails = {
      arrival: {
        formattedAddress: arrival.formatted_address,
        googlePlaceId: arrival.google_place_id,
      },
      departure: {
        formattedAddress: departure.formatted_address,
        googlePlaceId: departure.google_place_id,
      },
      mileage_calculation: {
        distanceMeters: mileage_calculation.distance_meters,
        rate: mileage_calculation.rate,
      },
    };

    return super.normalize(model, { ...normalizedJourneyDetails, ...rest }, prop);
  }
}
