import Service, { service } from '@ember/service';
import { decamelize } from '@ember/string';

import { apiBaseURL, internationalOutNamespace } from 'qonto/constants/hosts';
import { ELIGIBILITY_STATUS } from 'qonto/constants/international-out/eligibility';
import { DEFAULT_SOURCE_CURRENCY } from 'qonto/constants/international-out/quote';
import {
  normalizeConfirmation,
  normalizeFee,
  normalizeQuote,
} from 'qonto/utils/international-out/normalize';
import removeNullValues from 'qonto/utils/remove-null-values';
import transformKeys from 'qonto/utils/transform-keys';

export default class InternationalOutManager extends Service {
  @service networkManager;
  @service organizationManager;

  /**
   * Unique identifier used to ensure idempotency.
   *
   * @type {string|null}
   * @default null
   */
  idempotencyKey = null;

  /**
   * Generates a unique idempotency key using the randomUUID() method of the Crypto interface and assigns it to the service.
   *
   * @function addIdempotencyKey
   * @returns {void}
   */
  addIdempotencyKey() {
    this.idempotencyKey = crypto.randomUUID();
  }

  /**
   * Removes the idempotency key from the service by setting it to null.
   *
   * @function removeIdempotencyKey
   * @returns {void}
   */
  removeIdempotencyKey() {
    this.idempotencyKey = null;
  }

  /**
   * Adds the idempotency key as a header to the network manager.
   *
   * @function addIdempotencyHeader
   * @returns {void}
   */
  addIdempotencyHeader() {
    this.networkManager.addIdempotencyHeader(this.idempotencyKey);
  }

  /**
   * Removes the idempotency key from the headers of the network manager.
   *
   * @function removeIdempotencyHeader
   * @returns {void}
   */
  removeIdempotencyHeader() {
    this.networkManager.removeIdempotencyHeader();
  }

  /**
   * @typedef {Object} Spendings
   * @property {number} currentMonth - The current month spending value of the membership.
   * @property {number} monthlyLimit - The monthly transfer limit value of the membership.
   * @property {number} perTransferLimit - The per transfer limit value of the membership.
   */

  /**
   * @typedef {Object} Confirmation
   * @property {Array<string>} warnings - A list of warning codes as strings. The list can be empty.
   * @property {boolean} canProcess - Indicates whether the transfer can be submitted.
   * @property {boolean} isAboveLimit - Indicates if any spending limit is exceeded.
   * @property {string|null} [limitType] - The type of limit exceeded. Defined only if the list of warning codes contains a code related to spending limits.
   * @property {Spendings} [spendings] - The spending limits data of the membership. Defined only if the list of warning codes contains a code related to spending limits.
   */

  /**
   * Confirms a transfer.
   *
   * @async
   * @function confirmTransfer
   * @param {Object} params - The parameters object.
   * @param {Amount} params.amount - The total source amount (value and currency code), including the transfer fees.
   * @param {string} params.bankAccountId - The ID of the bank account.
   * @param {string} params.beneficiaryId - The ID of the beneficiary.
   * @returns {Promise<Confirmation>} A promise that resolves to the confirmation response.
   * @throws If the network request fails or if the response is invalid.
   */
  async confirmTransfer({ amount, bankAccountId, beneficiaryId }) {
    let url = this.#buildURL('confirm');
    let response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        payload: transformKeys(
          {
            amount: {
              currency: amount.currency,
              value: amount.value.toString(),
            },
            bankAccountId,
            beneficiaryId,
            organizationId: this.organizationManager.organization.id,
          },
          decamelize
        ),
      }),
    });
    return normalizeConfirmation(transformKeys(response));
  }

  /**
   * Creates a new beneficiary.
   *
   * @async
   * @function createBeneficiary
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {string} params.currency - The currency (ISO 4217 Alphabetic Code) to associate the beneficiary with.
   * @param {string} params.type - The payment type to associate the beneficiary with.
   * @param {Object} params.details - The attributes to create the beneficiary with.
   * @returns {Promise<{ beneficiary: Beneficiary, fees: Fees, quote: Quote, targetAccountId: number }>} A promise that resolves to an object containing the patched quote, the newly created beneficiary, the transfer fees and the target account ID.
   * @throws If the network request fails or if the response is invalid.
   */
  async createBeneficiary({ quoteId, currency, type, details }) {
    let url = this.#buildURL(`beneficiaries`);
    let response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        payload: {
          currency,
          details,
          type,
        },
      }),
    });
    let { fees, quote, ...rest } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote), ...rest };
  }

  /**
   * @typedef {Object} Fees
   * @property {number} fix - The fixed fee amount.
   * @property {number} minimum - The minimum fee amount.
   * @property {number} total - The total fee amount.
   * @property {number} variable - The variable fee rate.
   */

  /**
   * @typedef {Object} Quote
   * @property {string} id - The ID of the quote.
   * @property {string} estimatedDelivery - The estimated delivery date (UTC-format string) of the transfer.
   * @property {string} expirationTime - The date (UTC-format string) the locked rate will expire.
   * @property {string} formattedEstimatedDelivery - The estimated delivery date (formatted as a human readable string) of the transfer.
   * @property {string} payIn - The mechanism used to fund the transfer (e.g. "BANK_TRANSFER").
   * @property {string} payOut - The mechanism used to deliver the transfer (e.g. "SHA").
   * @property {number} rate - The exchange rate value used for the conversion.
   * @property {'FIXED'|'FLOATING'} rateType - Whether the exchange rate value is "FIXED" or "FLOATING".
   * @property {number} sourceAmount - The source amount.
   * @property {string} sourceCurrency - The source currency (ISO 4217 Alphabetic Code).
   * @property {number} targetAmount - The target amount.
   * @property {string} targetCurrency - The target currency (ISO 4217 Alphabetic Code).
   * @property {'SOURCE'|'TARGET'} type - Whether the quote was created as "SOURCE" or "TARGET".
   */

  /**
   * Creates the quote resource required to create an internatinal transfer.
   * The quote contains useful information such as the exchange rate, the estimated delivery time and the methods the user can pay for the transfer.
   *
   * @async
   * @function createQuote
   * @param {Object} data - The data object containing amount and currency information to use as a payload for the request.
   * @param {number|null} data.targetAmount - The target amount.
   * @param {number|null} data.sourceAmount - The source amount.
   * @param {string} data.targetCurrency - The target currency (ISO 4217 Alphabetic Code).
   * @param {string} data.sourceCurrency - The source currency (ISO 4217 Alphabetic Code).
   * @returns {Promise<{ fees: Fees, quote: Quote }>} A promise that resolves to an object containing the fees and quote objects.
   * @throws If the network request fails or if the response is invalid.
   */
  async createQuote(data) {
    let url = this.#buildURL('quote');
    let response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify(data),
    });
    let { fees, quote } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote) };
  }

  /**
   * @typedef {Object} Amount
   * @property {number|string} value
   * @property {string} currency - The currency code (ISO 4217 Alphabetic Code) associated to the amount.
   */

  /**
   * @typedef {Object} Transfer
   * @property {string} id - The ID of the transfer stored by Qonto.
   * @property {string} bankAccountId - The ID of the bank account.
   * @property {string} beneficiaryId - The ID of the beneficiary.
   * @property {string} initiatorId - The ID of the membership which initiated the transfer.
   * @property {string} organizationId - The ID of the organization.
   * @property {string} provider - The name of the outgoing international transfer provider.
   * @property {string} providerObjectId - The ID of the transfer stored by the outgoing international transfer provider.
   * @property {Amount} sourceAmount - The source amount (value and currency code).
   * @property {Amount} targetAmount - The target amount (value and currency code).
   * @property {string} status - The status of the transfer.
   */

  /**
   * Creates the transfer (i.e. payment order) to the target account based on the quote resource.
   * Once created, the transfer needs to be funded. Otherwise, it will be automatically canceled.
   *
   * @async
   * @function createTransfer
   * @param {Object} params - The parameters object.
   * @param {Array<string>} [params.attachmentIds=[]] - A list of attachment IDs.
   * @param {string} params.bankAccountId - The ID of the bank account.
   * @param {string} params.beneficiaryId - The ID of the beneficiary stored by Qonto.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {number} params.targetAccountId - The ID of the beneficiary stored by the outgoing international transfer provider.
   * @param {Object} params.details - The transfer details.
   * @param {Amount} params.sourceAmount - The source amount (value and currency code), including the transfer fees.
   * @param {Amount} params.targetAmount - The target amount (value and currency code).
   * @returns {Promise<Transfer>} A promise that resolves to the transfer object.
   * @throws If the network request fails or if the response is invalid.
   */
  async createTransfer({
    attachmentIds = [],
    bankAccountId,
    beneficiaryId,
    quoteId,
    targetAccountId,
    details,
    sourceAmount,
    targetAmount,
  }) {
    let url = this.#buildURL('transfers');
    let { transfer } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        transfer: {
          attachmentIds,
          bankAccountId,
          beneficiaryId,
          quoteId,
          targetAccountId,
          details,
          totalSourceAmount: {
            currency: sourceAmount.currency,
            value: sourceAmount.value.toString(),
          },
          targetAmount: {
            currency: targetAmount.currency,
            value: targetAmount.value.toString(),
          },
        },
      }),
    });
    return transformKeys(transfer);
  }

  /**
   * Deletes a beneficiary.
   *
   * @async
   * @function deleteBeneficiary
   * @param {Beneficiary} beneficiary - The beneficiary to delete.
   * @returns {Promise<void>}
   * @throws If the network request fails or if the response is invalid.
   */
  async deleteBeneficiary({ id }) {
    let url = this.#buildURL(`beneficiaries/${id}`);
    await this.networkManager.request(url, {
      method: 'DELETE',
    });
  }

  /**
   * Retrieves the additional beneficiary requirements for a given quote and beneficiary.
   * Are returned only the requirements that are not explicitely marked as optional.
   *
   * @async
   * @function getAdditionalBeneficiaryRequirements
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {Object} params.beneficiary - The beneficiary object to get the additional requirements for.
   * @param {string} params.beneficiary.id - The ID of the selected beneficiary.
   * @param {string} params.beneficiary.currency - The currency of the selected beneficiary.
   * @param {string} params.beneficiary.paymentType - The payment type of the selected beneficiary.
   * @param {Object} [params.details] - The details containing the form data.
   * @returns {Promise<Requirements>} A promise that resolves to the additional beneficiary requirements.
   * @throws If the network request fails or if the response is invalid.
   */
  async getAdditionalBeneficiaryRequirements({ quoteId, beneficiary, details = {} }) {
    let additionalRequirements = await this.getBeneficiaryRequirements({
      quoteId,
      currency: beneficiary.currency,
      beneficiary,
      details,
    });

    let requiredAdditionalRequirements = additionalRequirements
      ?.map(accountType => ({
        ...accountType,
        fields: accountType.fields
          .map(({ group }) => group)
          .flat()
          .filter(({ required }) => required),
      }))
      .filter(accountType => accountType.fields.length > 0);

    return additionalRequirements?.filter(first =>
      requiredAdditionalRequirements.some(second => first.type === second.type)
    );
  }

  /**
   * @typedef {Object} Beneficiary
   * @property {string} id - The beneficiary's ID.
   * @property {string} name - The beneficiary's name.
   * @property {string} country - The beneficiary's country code (ISO 3166-1 Alpha-2 Code).
   * @property {string} currency - The beneficiary's currency.
   * @property {string} paymentType - The beneficiary's payment method type.
   * @property {string} accountIdentifier - The beneficiary's account identifier.
   * @property {string} [branchIdentifier] - The beneficiary's branch identifier (optional).
   * @property {string} [bankIdentifier] - The beneficiary's bank identifier (optional).
   */

  /**
   * Retrieves a list of beneficiaries based on the provided currency.
   *
   * @async
   * @function getBeneficiaries
   * @param {string} currency - The currency (ISO 4217 Alphabetic Code) for which to retrieve beneficiaries.
   * @returns {Promise<Array<Beneficiary>>} A Promise that resolves to an array of beneficiary objects.
   * @throws If the network request fails or if the response is invalid.
   */
  async getBeneficiaries(currency) {
    let url = this.#buildURL(`beneficiaries?currency=${currency}`);
    let { beneficiaries } = await this.networkManager.request(url);
    return transformKeys(beneficiaries);
  }

  /**
   * Gets the account details of a beneficiary.
   *
   * @async
   * @function getBeneficiaryDetails
   * @param {string} beneficiaryId - The ID of the beneficiary.
   * @returns {Promise<Array<{ key: string; title: string; value: string; }>>}
   * @throws If the network request fails or if the response is invalid.
   */
  async getBeneficiaryDetails(beneficiaryId) {
    let url = this.#buildURL(`beneficiaries/${beneficiaryId}`);
    let { localizedData } = await this.networkManager.request(url);
    return transformKeys(localizedData);
  }

  /**
   * Retrieves the beneficiary requirements for a given quote (and an optional beneficiary).
   * The data returned can be used to build a dynamic user interface for beneficiary creation or edition,
   * as it helps figuring out which fields are required to create a valid beneficiary for different currencies.
   *
   * @async
   * @function getBeneficiaryRequirements
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {string} params.currency - The target currency (ISO 4217 Alphabetic Code).
   * @param {Object} [params.beneficiary] - The beneficiary object to refresh the requirements for.
   * @param {string} [params.beneficiary.id] - The ID of the selected beneficiary.
   * @param {string} [params.beneficiary.paymentType] - The payment type of the selected beneficiary.
   * @param {Object} [params.details] - The details containing the form data.
   * @returns {Promise<Requirements>} A promise that resolves to the beneficiary requirements.
   * @throws If the network request fails or if the response is invalid.
   */
  async getBeneficiaryRequirements({ quoteId, currency, beneficiary = {}, details = {} }) {
    let { id, paymentType: type } = beneficiary;
    let url = this.#buildURL('beneficiary-requirements');
    let { requirements } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        ...(id && { beneficiaryId: id }),
        payload: {
          ...(type && { type }),
          currency,
          details: removeNullValues(details),
        },
      }),
    });
    return transformKeys(requirements);
  }

  /**
   * @typedef {Object} Eligibility
   * @property {'eligible'|'ineligible'} status - The eligibility status of the entity.
   * @property {'kyb_not_approved'|'flex_kyb_not_approved'|'unknown'|''} reason - The reason for ineligibility. It can have three values: 'kyb_not_approved', 'flex_kyb_not_approved', or 'unknown' if the status is 'ineligible'. This field will be an empty string if the status is 'eligible'.
   */

  /**
   * Fetches eligibility information regarding outgoing international transfers.
   *
   * @async
   * @function getEligibilityStatus
   * @returns {Promise<Eligibility>} A promise that resolves the eligibility information.
   * @throws If the network request fails or if the response is invalid.
   */
  async getEligibilityStatus() {
    let url = this.#buildURL('eligibility');
    let eligibility = await this.networkManager.request(url);
    return transformKeys(eligibility);
  }

  /**
   * @typedef {Object} Currency
   * @property {string} currencyCode - The currency code (ISO 4217 Alphabetic Code).
   * @property {string} countryCode - The country code from where the currency is originated (ISO 3166-1 Alpha-2 Code).
   * @property {number|null} suggestionPriority - Indicates the priority level for suggesting the currency. Higher values suggest higher priority. If a null value is provided, it means the currency should not be suggested.
   */

  /**
   * Fetches available target currencies based on the provided source currency.
   *
   * @async
   * @function getTargetCurrencies
   * @param {string} [sourceCurrency="EUR"] - The source currency (ISO 4217 Alphabetic Code).
   * @returns {Promise<Array<Currency>>} A Promise that resolves to an array containing the available target currencies for the given currency code.
   * @throws If the network request fails or if the response is invalid.
   */
  async getTargetCurrencies(sourceCurrency = DEFAULT_SOURCE_CURRENCY.currencyCode) {
    let url = this.#buildURL(`currencies?source=${sourceCurrency}`);
    let { currencies } = await this.networkManager.request(url);
    return transformKeys(currencies);
  }

  /**
   * @typedef {Object} Field
   * @property {string} key - The key that identifies the field (e.g. "reference").
   * @property {string} name - The name of the field (e.g. "Reference").
   * @property {'date'|'radio'|'select'|'text'} type - The type of the field. Can only be 'text', 'select', 'date' or 'radio'.
   * @property {boolean} refreshRequirementsOnChange - Indicates if the requirements should be refreshed on change to discover required lower level fields.
   * @property {boolean} required - Indicates whether the field is required.
   * @property {string|null} displayFormat - The display format pattern.
   * @property {string} example - The example value of the field. Can be an empty string. Can be used to display a placeholder.
   * @property {number|null} minLength - The minimum length allowed for the field. Only specified in case of a field of type 'text'.
   * @property {number|null} maxLength - The maximum length allowed for the field. Only specified in case of a field of type 'text'.
   * @property {RegExp|null} validationRegexp - The regular expression to validate the field format. Only specified in case of a field of type 'text'.
   * @param {Object|null} validationAsync - Asynchronous validation configuration.
   * @param {string} validationAsync.url - The URL to send an asynchronous validation request.
   * @param {Array} validationAsync.params - An array of objects representing the parameters to include in the validation request.
   * @param {string} validationAsync.params.key - The key representing the parameter to validate in the request.
   * @property {Array<Object>|null} valuesAllowed - The values allowed for the field. Can be used to populate a field of type 'select' or 'radio'. Is null otherwise.
   * @property {string} valuesAllowed.key - The key of the allowed value (e.g. "BUSINESS").
   * @property {string} valuesAllowed.name - The name of the allowed value (e.g. "Business").
   */

  /**
   * @typedef {Object} FieldsGroup
   * @property {string} name - The fields group description (e.g. "Reference").
   * @property {Array<Field>} group - The group of fields. Can include from 1 to N fields.
   */

  /**
   * @typedef {Object} Requirements
   * @property {string} type - The type of the requirements (e.g. "transfer").
   * @property {string} [title] - The title of the requirement (e.g. "Transfer").
   * @property {string|null} [usageInfo] - A user-facing information about the set of requirements. It can be used as the content of a disclaimer for example.
   * @property {Array<FieldsGroup>} fields - The set of fields groups to use in order to build a dynamic user interface. Can include from 1 to N groups.
   */

  /**
   * Retrieves the transfer requirements for a given quote.
   * The data returned can be used to build a dynamic user interface for transfer creation,
   * as it helps figuring out which fields are required to create a valid transfer.
   *
   * @async
   * @function getTransferRequirements
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {number} params.targetAccountId - The ID of the beneficiary stored by the outgoing international transfer provider.
   * @param {Object} [params.details] - The details containing the form data.
   * @returns {Promise<Requirements>} A promise that resolves to the transfer requirements.
   * @throws If the network request fails or if the response is invalid.
   */
  async getTransferRequirements({ quoteId, targetAccountId, details = {} }) {
    let url = this.#buildURL('transfer-requirements');
    let { requirements } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        targetAccountId,
        payload: {
          details: removeNullValues(details),
        },
      }),
    });
    return transformKeys(requirements);
  }

  /**
   * @typedef {Object} ProcessingEvent
   * @property {'creation'|'validation'|'shipment'|'completion'} name - The name of the transfer processing event.
   * @property {string|null} date - The name on which the transfer processing event has happened or should happen. Always defined if the status of the event is set to 'completed'.
   * @property {number} order - The order of the event in the chain (0-3).
   * @property {'completed'|'in_progress'|'awaiting'} status - The status of the transfer processing event.
   */

  /**
   * Retrieves the transfer timeline for a given transfer ID.
   * The timeline represents the chain of events of the processing transfer (creation, validation, shipment, completion).
   *
   * @async
   * @function getTransferTimeline
   * @param {string} transferId - The ID of the transfer.
   * @returns {Promise<Array<ProcessingEvent>>} A promise that resolves to the transfer timeline.
   * @throws If the network request fails or if the response is invalid.
   */
  async getTransferTimeline(transferId) {
    let url = this.#buildURL(`transfers/${transferId}/timeline`);
    let { timeline } = await this.networkManager.request(url);
    return transformKeys(timeline);
  }

  /**
   * Checks if the current organization is eligible for outgoing international transfers.
   *
   * @async
   * @function isEligible
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the entity is eligible for outgoing international transfers.
   */
  async isEligible() {
    try {
      let { status } = await this.getEligibilityStatus();
      return status === ELIGIBILITY_STATUS.ELIGIBLE;
    } catch {
      return false;
    }
  }

  /**
   * Updates the beneficiary details.
   *
   * @async
   * @function updateBeneficiary
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {string} params.beneficiaryId - The ID of the beneficiary to update.
   * @param {string} params.currency - The currency of the beneficiary to update.
   * @param {string} params.type - The payment type to associate the beneficiary with.
   * @param {Object} [params.details] - The new attributes to update the beneficiary with.
   * @returns {Promise<{ beneficiary: Beneficiary, fees: Fees, quote: Quote, targetAccountId: number }>} A promise that resolves to an object containing the patched quote, the updated beneficiary, the transfer fees and the target account ID.
   * @throws If the network request fails or if the response is invalid.
   */
  async updateBeneficiary({ quoteId, beneficiaryId, currency, type, details = {} }) {
    let url = this.#buildURL(`beneficiaries/${beneficiaryId}`);
    let response = await this.networkManager.request(url, {
      method: 'PUT',
      data: JSON.stringify({
        quoteId,
        payload: {
          currency,
          details,
          type,
        },
      }),
    });
    let { fees, quote, ...rest } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote), ...rest };
  }

  /**
   * Constructs a URL for outgoing international transfer requests by appending the provided path to the base URL.
   *
   * @function buildURL
   * @param {string} path - The path to be appended to the base URL.
   * @returns {string} The constructed URL.
   * @private
   */
  #buildURL = path => `${apiBaseURL}/${internationalOutNamespace}/international_out/${path}`;
}
