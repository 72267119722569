import {
  ALLOWED_FIELD_TYPES,
  COMPONENT_NAMESPACE,
  COUNTRY_TRIGGER_SELECT_COMPONENT,
  DEFAULT_TRIGGER_SELECT_COMPONENT,
} from 'qonto/constants/dynamic-form';
import isFunction from 'qonto/utils/is-function';

/**
 * Creates a map object with default values for specified keys.
 *
 * @param {Object} options - The options object.
 * @param {Array} options.keys - An array of keys for the map.
 * @param {(*|function)} options.defaultValue - The default value to be assigned to each key. It can be a fixed value or a function that receives a key and returns the default value.
 * @returns {Object} A map object with keys from the input array, each initialized with the default value.
 *
 * @example
 * // Creating a default map with fixed default value
 * const options = {
 *   keys: ['key1', 'key2', 'key3'],
 *   defaultValue: true,
 * };
 * const defaultMap = createDefaultMap(options);
 * // defaultMap: { key1: true, key2: true, key3: true }
 *
 * // Creating a default map with dynamic default value using a function
 * const optionsWithFunction = {
 *   keys: ['key1', 'key2', 'key3'],
 *   defaultValue: key => key.length,
 * };
 * const dynamicDefaultMap = createDefaultMap(optionsWithFunction);
 * // dynamicDefaultMap: { key1: 3, key2: 3, key3: 3 }
 */
export function createDefaultMap({ keys, defaultValue }) {
  return keys.reduce(
    (map, key) => ({
      ...map,
      [key]: isFunction(defaultValue) ? defaultValue(key) : defaultValue,
    }),
    {}
  );
}

/**
 * Extends the properties of a given field object by adding additional properties
 * such as:
 * - `component`
 * - `triggerComponent`
 *
 * @param {Object} field - The field object to extend.
 * @param {string} field.key - The key identifying the field.
 * @param {string} field.type - The type of the field.
 *
 * @returns {Object} - The extended field object with additional properties.
 * @property {string} component - The name of the component associated with the field's type.
 * @property {string} triggerComponent - The name of the trigger component based on the field's key and type.
 */
export function extendFieldProperties(field) {
  let { key, type } = field;

  return {
    ...field,
    component: getFieldComponentName(type),
    triggerComponent: getTriggerComponentName(key, type),
  };
}

/**
 * Extends the properties of a given fieldset object by adding an additional property `component`.
 *
 * @param {Object} fieldset - The original fieldset object to be extended.
 *
 * @returns {Object} The extended fieldset object with the additional property.
 * @property {string} component - The name of the component associated to the fieldset.
 */
export function extendFieldsetProperties(fieldset) {
  return {
    ...fieldset,
    component: `${COMPONENT_NAMESPACE}/fieldset`,
  };
}

/**
 * Gets the component name based on the given field type.
 *
 * @param {string} fieldType - The type of the field.
 * @returns {string|null} The component name associated with the field type, or null if the field type is not recognized.
 *
 * @example
 * const componentName = getFieldComponentName('date');
 * // componentName: '[...]/date-picker-field'
 */
export function getFieldComponentName(fieldType) {
  let { DATE, RADIO, SELECT, TEXT } = ALLOWED_FIELD_TYPES;
  switch (fieldType) {
    case DATE:
      return `${COMPONENT_NAMESPACE}/date-picker-field`;
    case RADIO:
      return `${COMPONENT_NAMESPACE}/radio-group-field`;
    case SELECT:
      return `${COMPONENT_NAMESPACE}/select-field`;
    case TEXT:
      return `${COMPONENT_NAMESPACE}/text-field`;
    default:
      return null;
  }
}

/**
 * Gets the trigger component name based on the given field key and type.
 *
 * @param {string} fieldKey - The key of the field.
 * @param {string} fieldType - The type of the field.
 * @returns {string|null} The trigger component name, or null if not applicable.
 *
 * @example
 * // Returns the country trigger component name as a string for a fieldKey containing 'country'
 * getTriggerComponentName('country-select', 'select');
 *
 * // Returns the default trigger component name as a string for a non-'country' fieldKey with fieldType 'select'
 * getTriggerComponentName('other-select', 'select');
 *
 * // Returns null for any other field type
 * getTriggerComponentName('other-field', 'text');
 */
export function getTriggerComponentName(fieldKey, fieldType) {
  if (fieldType === ALLOWED_FIELD_TYPES.SELECT) {
    return fieldKey.includes('country')
      ? COUNTRY_TRIGGER_SELECT_COMPONENT
      : DEFAULT_TRIGGER_SELECT_COMPONENT;
  }

  return null;
}

/**
 * Merges adjacent fieldsets with the same name (i.e. subject) in a given array of fieldsets, while
 * keeping the original fields order.
 *
 * @param {Array<{ name: string | null, fields: Array<Object> }>} fieldsets - The input array of fieldsets to merge.
 * @returns {Array<{ name: string | null, fields: Array<Object> }>} The array of merged fieldsets.
 *
 * @example
 *
 * const fieldsets = [
 *   { legend: null, fields: [{ key: 'name', ... }] },
 *   { legend: 'address', fields: [{ key: 'address.country', ... }] },
 *   { legend: 'address', fields: [{ key: 'address.city', ... }] },
 *   { legend: null, fields: [{ key: 'account-number', ... }] },
 * ];
 *
 * const mergedFieldsets = mergeAdjacentFieldsets(fieldsets);
 * // mergedFieldsets:
 * // [
 * //   { legend: null, fields: [{ key: 'name', ... }] },
 * //   { legend: 'address', fields: [{ key: 'address.country', ... }, { key: 'address.city', ... }] },
 * //   { legend: null, fields: [{ key: 'account-number', ... }] },
 * // ]
 */
export function mergeAdjacentFieldsets(fieldsets) {
  return fieldsets.reduce((accumulator, currentFieldset) => {
    let lastFieldset = accumulator.at(-1);

    if (lastFieldset?.legend === currentFieldset.legend) {
      lastFieldset.fields.push(...currentFieldset.fields);
    } else {
      accumulator.push({
        legend: currentFieldset.legend,
        fields: [...currentFieldset.fields],
      });
    }

    return accumulator;
  }, []);
}

/**
 * Transforms an array of field objects into an array of fieldsets,
 * each containing a name and an array with the field object.
 *
 * @param {Array<Object>} fields - The input array of field objects.
 * @returns {Array<{ name: string | null, fields: Array<Object> }>} The transformed array of fieldsets.
 *
 * @example
 *
 * const fields = [
 *   { key: 'name', ... },
 *   { key: 'address.country', ... },
 *   { key: 'address.city', ... },
 *   { key: 'account-number', ... },
 * ];
 *
 * const fieldsets = transformFieldsToFieldsets(fields);
 * // fieldsets:
 * // [
 * //   { legend: null, fields: [{ key: 'name', ... }] },
 * //   { legend: 'address', fields: [{ key: 'address.country', ... }] },
 * //   { legend: 'address', fields: [{ key: 'address.city', ... }] },
 * //   { legend: null, fields: [{ key: 'account-number', ... }] },
 * // ]
 */
export function transformFieldsToFieldsets(fields) {
  return fields.map(field => {
    let fieldKeyParts = field.key.split('.');
    return {
      legend: fieldKeyParts.length > 1 ? fieldKeyParts[0] : null,
      fields: [field],
    };
  });
}
