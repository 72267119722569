import { camelize } from '@ember/string';

import { COUNTRY_MISMATCH_ERROR_CODES } from 'qonto/constants/beneficiaries';
import { SEPA_COUNTRY_CODES_REGEX as SEPA_COUNTRY_CODES } from 'qonto/constants/transfers';

/**
 * Checks whether the provided IBAN starts with a valid SEPA country code.
 * There are 52 countries listed in the SEPA schemes’ geographical scope.
 *
 * @param {string} iban - The IBAN to validate.
 * @returns {boolean} `true` if the IBAN starts with a valid SEPA country code, `false` otherwise.
 *
 * @example
 * isIbanFromSepaZone('GB29 NWBK 6016 1331 9268 19'); // returns true
 * isIbanFromSepaZone('US64 SVBK 0016 0077 6169 95'); // returns false
 */
export function isIbanFromSepaZone(iban) {
  return SEPA_COUNTRY_CODES.test(iban);
}

/**
 * If the country identifier is not supported, it is mapped as a specific error.
 * @param {ApiAdaptersInvalidError} error
 * @param {Array<String>} countryCodes
 * @returns {ApiAdaptersInvalidError}
 */
export function mapUnsupportedIdentifierCountryError(error, countryCodes) {
  if (
    COUNTRY_MISMATCH_ERROR_CODES.includes(error.detail.code) &&
    !countryCodes.includes(error.detail.meta?.identifier_country)
  ) {
    return {
      ...error,
      detail: {
        ...error.detail,
        code: 'unsupported_identifier_country',
        source: {
          pointer: `beneficiary/${error.detail.code === 'country_iban_mismatch' ? 'iban' : 'bic'}`,
        },
      },
    };
  }
  return error;
}

/**
 * Parse JSONApi like invalid Errors to errors to inject in the model
 * @param {Array<ApiAdaptersInvalidErrors>} errors
 * @returns {[fieldName]:[error_code]} Errors to be injected inside the model
 */
export function parseInvalidBeneficiaryErrors(errors = []) {
  return errors
    .map(e => e.detail)
    .filter(e => Boolean(e.source?.pointer))
    .reduce((acc, { source, code }) => {
      let attribute = source.pointer.split('/').pop();
      return {
        [camelize(attribute)]: code,
        ...acc,
      };
    }, {});
}

export default {
  mapUnsupportedIdentifierCountryError,
  parseInvalidBeneficiaryErrors,
};
