import Service, { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { DIRECT_DEBIT_COLLECTION_STATUS } from 'qonto/constants/direct-debit-collections';

export default class DirectDebitCollectionsManager extends Service {
  @service store;
  @service organizationManager;
  @service router;
  @service flowLinkManager;
  @service segment;
  @service modals;

  async loadMandates(clientId) {
    return await this.store.query('direct-debit-collection-mandate', {
      client_id: clientId,
    });
  }

  getTotalOnHoldMetaTask = dropTask(async bankAccount => {
    let allStatuses = [
      DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED,
      DIRECT_DEBIT_COLLECTION_STATUS.CANCELED,
      DIRECT_DEBIT_COLLECTION_STATUS.DECLINED,
      DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED,
      DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD,
    ];

    let collections = await this.store.query('direct-debit-collection', {
      organization_id: this.organizationManager.organization.id,
      filters: {
        status: allStatuses,
        bank_account_ids: [bankAccount],
      },
      page: 1,
      per_page: 1,
    });

    return collections.meta?.total_on_hold;
  });

  handleRedirectionOnSetupCollection({ redirectRoute }) {
    if (variation('feature--boolean-sdd-creation-non-kyc')) {
      let { membership } = this.organizationManager;

      if (membership.shouldSubmitKyc) {
        return this.router.replaceWith('kyc.intro', {
          queryParams: {
            redirectRoute,
          },
        });
      } else if (membership.kycSubmitted && !membership.kycAccepted) {
        return this.modals.open('direct-debit-collections/kyc-pending-sdd-creation-blocked-popup');
      }
    }

    this.flowLinkManager.transitionTo({
      name: 'direct-debit-subscription',
      stepId: 'type-selection',
    });

    this.segment.track('incoming-direct-debit_collection-flow_accessed');
  }
}
