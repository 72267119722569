import { buildWaiter } from '@ember/test-waiters';

import { enqueueTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import BaseInvoicesUploadManager from 'qonto/services/invoices-upload-manager';
import { ErrorInfo } from 'qonto/utils/error-info';
import pushPayload from 'qonto/utils/store-push-payload';

let waiter = buildWaiter('invoices-upload-manager');
const QUEUE_NAME = 'supplier-invoices';

export default class SupplierInvoicesUploadManager extends BaseInvoicesUploadManager {
  uploadUrl = `${apiBaseURL}/v1/supplier_invoices/bulk`;
  queueName = QUEUE_NAME;

  constructor() {
    super(...arguments, {
      queueName: QUEUE_NAME,
    });
  }

  uploadTask = enqueueTask({ maxConcurrency: 3 }, async fileUploadState => {
    let token = waiter.beginAsync();
    let file = fileUploadState.uploadFile;

    try {
      let data = {
        'supplier_invoices[][idempotency_key]': crypto.randomUUID(),
        source: this.queueName.replace(/-/g, '_'),
      };

      let newFileResponse = await file.upload(this.uploadUrl, {
        withCredentials: true,
        fileKey: 'supplier_invoices[][file]',
        data,
        headers: {
          'X-Qonto-Organization-ID': this.organizationManager.organization.id,
        },
      });
      let payload = await newFileResponse.json();
      let { supplier_invoices, errors } = payload;

      if (supplier_invoices?.length) {
        let supplierInvoices = pushPayload(this.store, 'supplier-invoice', { supplier_invoices });

        if (supplierInvoices.length) {
          let supplierInvoice = supplierInvoices[0];
          fileUploadState.attachment = await this.store.findRecord(
            'attachment',
            supplierInvoice.belongsTo('attachment').id()
          );
          // We need the invoice id to link to the details page
          fileUploadState.invoiceId = supplierInvoice.id;
        }
      }

      if (errors?.length) {
        let translatedErrors = [];

        for (let error of errors) {
          // 'invalid' is an internal Server error, we only log other errors to Sentry.
          if (error.code !== 'invalid') {
            this.sentry.captureMessage(error.detail, { cft: 'invoices' });
          }

          // There is no support yet for the embedded error messages the server can send back, so we show a generic error.
          translatedErrors.push(this.intl.t('supplier-invoices.importing-modal.error.generic'));
        }

        fileUploadState.errors = [...fileUploadState.errors, ...translatedErrors];
      }

      this.onUploadFinished?.();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, { cft: 'invoices' });
      }
      fileUploadState.errors = [this.intl.t('supplier-invoices.importing-modal.error.generic')];
      file.queue?.remove(file);
      this.onUploadErrors?.();
    } finally {
      waiter.endAsync(token);
    }
  });

  get importingPopoverVisible() {
    return Boolean(this.files.length);
  }
}
