import ApplicationSerializer from 'qonto/serializers/application';

export default class OrganizationSubscriptionNewSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(payloadKey) {
    let key = payloadKey;
    if (payloadKey === 'subscriptions') {
      key = 'organization-subscription-new';
    }

    return super.modelNameFromPayloadKey(key);
  }

  payloadKeyFromModelName() {
    return 'subscription';
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let products = [];
    payload.subscriptions?.forEach(val => {
      if (val.product) {
        val.product_id = val.product.id;
        products.push(val.product);
        delete val.product;
      }
    });

    if (products.length) {
      payload['subscription-product'] = products;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
