import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const VAT_VALIDATIONS = {
  FR: /^\d{14}$/,
  IT: /^\d{11}$/,
};

const KycKybUpdateOrganizationChangeRequestValidations = buildValidations(
  {
    addressCity: {
      validators: [validator('ds-error'), validator('presence', true)],
    },
    addressFirstLine: {
      validators: [
        validator('ds-error'),
        validator('presence', true),
        validator('length', { min: 2 }),
      ],
    },
    addressZipCode: {
      validators: [
        validator('ds-error'),
        validator('presence', true),
        validator('length', { min: 2 }),
      ],
    },
    contactEmail: {
      validators: [
        validator('ds-error'),
        validator('presence', true),
        validator('format', {
          type: 'email',
          minTldLength: 2,
        }),
      ],
    },
    legalName: [validator('ds-error'), validator('presence', true)],
    legalCode: [validator('ds-error'), validator('presence', true)],
    legalNumber: [validator('ds-error'), validator('presence', true)],
    legalSector: [validator('ds-error'), validator('presence', true)],
    legalRegistrationDate: [validator('ds-error'), validator('presence', true)],
    taxCountry: [validator('ds-error'), validator('presence', true)],
    taxIdentificationNumber: [
      validator('tin-format', {
        taxCountryFormat: computed('model.taxCountry', function () {
          return VAT_VALIDATIONS[this.model.taxCountry];
        }),
        messageKey: {
          wrongFormat:
            'organizations.profile.company_profile.organization_edit.tax_identification_number_error',
          fieldIsMissing: 'validations.errors.blank',
        },
      }),
    ],
    annualTurnover: [
      validator('presence', {
        presence: true,
      }),
    ],
    yearlyTransactionVolume: [
      validator('presence', {
        presence: true,
      }),
    ],
    maxSinglePaymentAmount: [
      validator('presence', {
        presence: true,
        ignoreBlank: true,
      }),
    ],
    natureOfOperations: [
      validator('collection', {
        collection: true,
        messageKey: 'validations.errors.blank',
      }),
    ],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default KycKybUpdateOrganizationChangeRequestValidations;
