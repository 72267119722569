interface Currency {
  /**
   * The ISO 3166-1 alpha-2 country code associated with the currency (e.g. 'US' for United States).
   */
  countryCode: string;

  /**
   * The ISO 4217 currency code (e.g. 'USD' for US Dollar).
   */
  currencyCode: string;

  /**
   * The suggestion priority of the currency for sorting in suggestions.
   * A higher number indicates higher priority. A `null` value indicates no specific priority.
   */
  suggestionPriority: number | null;
}

interface EnhancedCurrency extends Currency {
  /**
   * The display name of the currency (e.g. 'US Dollar').
   */
  currencyDisplayName?: string;

  /**
   * An array of keywords associated with the currency for search and suggestion purposes.
   */
  keywords: string[];
}

/**
 * Extends currency object with additional properties such as:
 * - currency display name
 * - keywords list
 *
 * @param options - The options object.
 * @param options.currency - The currency object.
 * @param options.currency.currencyCode - The currency code.
 * @param options.currency.countryCode - The country code.
 * @param options.currency.rest - Additional properties of the currency object.
 * @param options.locale - The locale used for localization.
 * @returns An extended currency object.
 *
 * @example
 *
 * ```ts
 * const extendedCurrency = extendCurrency({
 *   currency: {
 *     currencyCode: 'USD',
 *     countryCode: 'US',
 *     ...
 *   },
 *   locale: 'en'
 * });
 * // {
 * //   currencyCode: 'USD',
 * //   currencyDisplayName: 'US Dollar',
 * //   countryCode: 'US',
 * //   keywords: ['USD', 'US', 'US Dollar'],
 * //   ...
 * // }
 * ```
 */
function extendCurrency<T extends Currency>({
  currency,
  locale,
}: {
  currency: T;
  locale: string;
}): T & EnhancedCurrency {
  const { currencyCode, countryCode } = currency;
  const currencyDisplayName = getFullCurrencyName({ currencyCode, locale });
  const keywords = [
    ...new Set([currencyCode, countryCode, ...(currencyDisplayName ? [currencyDisplayName] : [])]),
  ];

  return {
    ...currency,
    currencyCode,
    currencyDisplayName,
    countryCode,
    keywords,
  };
}

/**
 * Retrieves the number of allowed decimal places for a given currency code.
 *
 * @param currencyCode - The currency code (e.g. 'USD', 'EUR').
 * @returns The number of allowed decimal places for the specified currency.
 *
 * @example
 *
 * ```ts
 * getAllowedDecimalPlaces('JPY'); // Returns 0 (no decimal places for JPY)
 * getAllowedDecimalPlaces('USD'); // Returns 2 (default for USD)
 * getAllowedDecimalPlaces('BHD'); // Returns 3 (default for BHD)
 * ```
 */
function getAllowedDecimalPlaces(currencyCode: string): number {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });

  return formatter.resolvedOptions().minimumFractionDigits || 0;
}

/**
 * Calculates the corresponding amount interval for a given currency code.
 *
 * @param currencyCode - The currency code for which to calculate the corresponding amount interval.
 * @returns The corresponding amount interval for the specified currency code.
 *
 * @example
 *
 * ```ts
 * getCorrespondingAmountInterval('JPY'); // Returns 1 (no decimal places for JPY)
 * getCorrespondingAmountInterval('USD'); // Returns 0.01 (default for USD)
 * getCorrespondingAmountInterval('BHD'); // Returns 0.001 (default for BHD)
 * ```
 */
function getCorrespondingAmountInterval(currencyCode: string): number {
  return Math.pow(10, -getAllowedDecimalPlaces(currencyCode));
}

/**
 * Generates a placeholder string representing a zero amount with appropriate decimal places based on currency code.
 *
 * @param currencyCode - The currency code for which the placeholder is generated.
 * @param decimalPoint - The character used as the decimal point.
 * @returns A placeholder string representing a zero amount with appropriate decimal places.
 *
 * @example
 *
 * ```ts
 * getCorrespondingAmountPlaceholder('JPY'); // Returns '0'
 * getCorrespondingAmountPlaceholder('USD'); // Returns '0,00'
 * getCorrespondingAmountPlaceholder('BHD'); // Returns '0,000'
 * getCorrespondingAmountPlaceholder('USD', '.'); // Returns '0.00'
 * ```
 */
function getCorrespondingAmountPlaceholder(currencyCode: string, decimalPoint = ','): string {
  const numberOfDecimals = getAllowedDecimalPlaces(currencyCode);
  return '0' + (numberOfDecimals > 0 ? decimalPoint + '0'.repeat(numberOfDecimals) : '');
}

/**
 * Retrieves the full currency name corresponding to the given currency code and locale.
 *
 * @param options - The options object.
 * @param options.currencyCode - The currency code to get the full name for.
 * @param options.locale - The locale used for formatting (e.g. 'en-US', 'fr-FR').
 * @returns The full name of the currency corresponding to the provided code and locale.
 *
 * @example
 *
 * ```ts
 * getFullCurrencyName({
 *   currencyCode: 'USD'
 *   locale: 'en-US'
 * }); // Returns 'US Dollar'
 * ```
 */
function getFullCurrencyName({
  currencyCode,
  locale,
}: {
  currencyCode: string;
  locale: string;
}): string | undefined {
  return new Intl.DisplayNames(locale, { type: 'currency' }).of(currencyCode);
}

/**
 * Groups currencies into two arrays based on their suggestion priority.
 * Currencies with a `suggestionPriority` property are considered popular and sorted accordingly.
 * Currencies without a `suggestionPriority` property are grouped separately.
 *
 * @param currencies - An array of currency objects.
 * @returns An array containing two arrays: the first being the popular currencies and the other one being the rest of the currencies.
 *
 * @example
 *
 * ```ts
 * const currencies = [
 *   { currencyCode: 'USD', ..., suggestionPriority: 1 },
 *   { currencyCode: 'EUR', ..., suggestionPriority: 2 },
 *   { currencyCode: 'GBP', ..., suggestionPriority: null },
 *   { currencyCode: 'JPY', ..., suggestionPriority: 3 },
 *   { currencyCode: 'AED' },
 * ];
 * const [popular, others] = groupCurrencies(currencies);
 * console.log(popular); // [{ currencyCode: 'JPY', ..., suggestionPriority: 3 }, { currencyCode: 'EUR', ..., suggestionPriority: 2 }, { currencyCode: 'USD', ..., suggestionPriority: 1 }]
 * console.log(others); // [{ currencyCode: 'GBP', ..., suggestionPriority: null }, { currencyCode: 'AED' },]
 * ```
 */
function groupCurrencies(currencies: Currency[]): Currency[][] {
  const popularCurrencies = currencies.filter(
    currency =>
      currency.hasOwnProperty('suggestionPriority') &&
      currency.suggestionPriority !== null &&
      typeof currency.suggestionPriority === 'number'
  );

  popularCurrencies.sort(
    // @ts-ignore: type narrowing issues on `Array` methods - it would be fixed with `typescript@5.5`
    (currency, otherCurrency) => otherCurrency.suggestionPriority - currency.suggestionPriority
  );

  const otherCurrencies = currencies.filter(currency => !popularCurrencies.includes(currency));

  return [popularCurrencies, otherCurrencies];
}

export {
  extendCurrency,
  getAllowedDecimalPlaces,
  getCorrespondingAmountInterval,
  getCorrespondingAmountPlaceholder,
  getFullCurrencyName,
  groupCurrencies,
};
