// @ts-nocheck
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import BaseSessionService from 'ember-simple-auth/services/session';
import window from 'ember-window-mock';

export const routeAfterAuthentication = 'protected.index';

export default class SessionManagerService extends BaseSessionService<unknown> {
  @service store;
  @service segment;
  @service router;
  @service userManager;
  @service navigationGuard;
  @service zendeskWidget;

  prohibitAuthentication(route = routeAfterAuthentication) {
    return super.prohibitAuthentication(route);
  }

  // override simple-auth's handleAuthentication not to play default transitions
  handleAuthentication() {}

  handleInvalidation() {
    this.segment.reset();
    this.zendeskWidget.unmount();

    // Try to also record cookie state when the user got auth by Google.
    // This prevents a UX dead loop.
    window.google?.accounts?.id?.disableAutoSelect?.();

    let route = '/';
    let toRedirect = this.get('userManager.redirectUponLogout');
    if (toRedirect) {
      route = `/${toRedirect}`;
    }

    // ? We want to suspend the navigation guard to force the redirection without user's confirmation
    this.navigationGuard.suspend(() => {
      if (macroCondition(isTesting())) {
        this.store.unloadAll();

        // we can't use `this.transitionTo(route)` directly, because then the
        // `beforeModel()` hook of the `protected` route won't fire, because
        // we are already coming from a subroute of it. we work around that
        // by first transitioning out of the `protected` route and then replacing
        // the route with the originally intended route, which makes sure that
        // `beforeModel()` correctly runs.
        this.router.transitionTo('logout').then(() => this.router.replaceWith(route));
      } else {
        window.location.replace(route);
      }
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    sessionManager: SessionManagerService;
  }
}
