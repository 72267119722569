import { ForbiddenError, NotFoundError } from '@ember-data/adapter/error';
import { assert } from '@ember/debug';
import { get } from '@ember/object';
import Service, { service } from '@ember/service';
import { camelize } from '@ember/string';
import { waitFor } from '@ember/test-waiters';
import { cached, tracked } from '@glimmer/tracking';

import { isDevelopingApp, macroCondition } from '@embroider/macros';
import { variation } from 'ember-launch-darkly';

import { SUBSCRIPTION_RECURRENCES, SUBSCRIPTION_STATUS } from 'qonto/constants/subscriptions';
import { ignore404 } from 'qonto/utils/ignore-error';

/**
 * Subscription manager
 *
 * @class SubscriptionManagerService
 * @module qonto/services/subscription-manager
 * @extends Ember.Service
 */

export default class SubscriptionManagerService extends Service {
  @service organizationManager;
  @service segment;
  @service abilities;
  @service sentry;
  @service store;
  @service intl;

  @tracked subscriptions = null;
  @tracked _currentSubscription = null;
  @tracked _currentPricePlan = null;
  @tracked subscriptionFeatures = null;
  @tracked subscriptionOptions = null;

  hasFeature(featureCode) {
    if (this.subscriptionFeatures) {
      return Boolean(this.subscriptionFeatures?.find(({ code }) => code === featureCode));
    } else {
      return Boolean(this.currentPricePlan?.features.includes(featureCode));
    }
  }

  @waitFor
  async upgradeRecommendation(featureCode) {
    if (this.organizationManager.organization?.hasModularPricing) {
      return this.store
        .adapterFor('organization-subscription-new')
        .upgradeRecommendation(featureCode);
    } else {
      return await this.currentSubscription.upgradeRecommendation(featureCode);
    }
  }

  @waitFor
  async hasUpgrade(featureCode) {
    if (this.organizationManager.organization?.hasModularPricing) {
      try {
        await this.upgradeRecommendation(featureCode);
      } catch (error) {
        if (error instanceof NotFoundError || error instanceof ForbiddenError) {
          return false;
        }

        throw error;
      }

      return true;
    } else {
      return await this.currentSubscription.hasUpgrade(featureCode);
    }
  }

  getLimitByFeatureCode(featureCode) {
    if (this.subscriptionFeatures) {
      let limit = this.getLimitObjectByFeatureCode(featureCode);
      return limit === null || limit === undefined ? limit : limit.value;
    } else {
      let mapping = {
        physical_card: 'physicalCardLimit',
        virtual_card: 'virtualCardLimit',
        additional_users: 'userLimit',
        sepa_transfers: 'sepaOutLimit',
        checks: 'checkLimit',
        multi_accounts: 'bankAccountLimit',
        receivable_invoices: 'receivableInvoiceGlobalLimit',
        accountant_access: 'accountantLimit',
      };
      if (!this.currentPricePlan) {
        this.sentry.captureMessage(
          'price plan missing in subscriptionManager.getLimitByFeatureCode',
          { cft: 'pricing' }
        );
        if (macroCondition(isDevelopingApp())) {
          throw new Error('price plan missing in subscriptionManager.getLimitByFeatureCode');
        }
        return;
      }

      let key = mapping[featureCode];
      return this.currentPricePlan[key];
    }
  }

  getLimitObjectByFeatureCode(featureCode) {
    let feature = this.subscriptionFeatures?.find(({ code }) => code === featureCode);
    return feature === undefined ? undefined : feature.limit;
  }

  @waitFor
  async loadSubscription(organization) {
    if (organization.underRegistration) return;

    if (organization.hasModularPricing) {
      return await this.store.query('organization-subscription-new', {
        includes: ['product'],
      });
    } else {
      return await this.store.queryRecord('organization-subscription', {
        organization_id: organization.id,
      });
    }
  }

  @cached
  get features() {
    if (this.subscriptionFeatures) {
      return this.subscriptionFeatures.reduce((acc, { code }) => {
        acc[camelize(code)] = true;
        return acc;
      }, {});
    } else {
      if (this.currentSubscription?.status === SUBSCRIPTION_STATUS.ACTIVE) {
        return this.currentPricePlan?.featuresObject || {};
      } else {
        return {};
      }
    }
  }

  @cached
  get options() {
    if (this.subscriptionOptions) {
      return this.subscriptionOptions.reduce((acc, { code, price }) => {
        acc[camelize(code)] = price;
        return acc;
      }, {});
    } else {
      return get(this.currentPricePlan, 'optionsObject') || {};
    }
  }

  async refresh(organization = this.organizationManager.organization) {
    // Ignore 404 failure on GET /subscriptions
    // it might not be created yet
    if (organization.hasModularPricing) {
      this.subscriptions = await this.loadSubscription(organization).catch(ignore404);
      let [features, options] = await Promise.all([
        this.store.query('subscriptions-feature', {}),
        this.store.query('subscriptions-option', {}),
      ]);

      this.subscriptionFeatures = features;
      this.subscriptionOptions = options;
    } else {
      let subscription = await this.loadSubscription(organization).catch(ignore404);
      let pricePlan = await subscription?.belongsTo('pricePlan').load();

      if (variation('feature--boolean-mp-epic2b')) {
        let [subscriptionFeatures, options] = await Promise.all([
          this.store.query('subscriptions-feature', {}),
          this.store.query('subscriptions-option', {}),
        ]);

        this.subscriptionFeatures = subscriptionFeatures;
        this.subscriptionOptions = options;
      }

      this._currentSubscription = subscription;
      this._currentPricePlan = pricePlan;
    }

    this.resetTracking();
  }

  resetTracking() {
    let { currentSubscription, currentPricePlan } = this;
    this.segment.identify({
      freeTrialCurrent: currentSubscription?.activeTrial ? currentPricePlan?.get('code') : 'none',
      freeTrialEligibility: currentSubscription?.availableTrials?.length
        ? currentSubscription?.availableTrials?.map(({ pricePlanCode }) => pricePlanCode)
        : 'none',
    });
  }

  get currentSubscription() {
    if (this.organizationManager.organization?.hasModularPricing) {
      return this.subscriptions?.find(({ product }) => !product.isAddon);
    } else {
      return this._currentSubscription;
    }
  }
  set currentSubscription(subscription) {
    this._currentSubscription = subscription;
  }

  get currentPricePlan() {
    if (this.organizationManager.organization?.hasModularPricing) {
      return this.currentSubscription?.product;
    } else {
      return this._currentPricePlan;
    }
  }
  set currentPricePlan(plan) {
    this._currentPricePlan = plan;
  }

  get currentAddons() {
    return this.subscriptions?.filter(({ product }) => product.isAddon);
  }

  /**
   * Retrieve subscription's pricePlan from an organization identified by its slug
   *
   * @public
   * @method getSubscriptionPricePlanFor
   *
   * @param {String} slug
   * @returns  {Promise.<PricePlan|undefined>}
   */
  async getSubscriptionPricePlanFor(slug) {
    let organization = await this.organizationManager.getOrganizationBySlug(slug);
    let subscription = await this.loadSubscription(organization);
    /**
     * loadSubscription could return undefined if it's under registration
     * so belongsTo would fail. This function is used for the notifications page
     * where it loads all organizations and subscriptions so the whole page crashes
     * therefore we just catch it below so at least the page loads.
     */
    return subscription?.belongsTo('pricePlan').load();
  }

  get nextRecurrenceDate() {
    return this.currentSubscription.nextRecurrenceDate;
  }

  get nextInvoicingDate() {
    return this.currentSubscription.nextInvoicingDate;
  }

  /**
   * Has the current subscriptions max number of bank accounts been reached?
   *
   * @public
   * @method hasReachedBankAccountLimit
   *
   * @return {Boolean}
   */
  get hasReachedBankAccountLimit() {
    let { length } = this.organizationManager.organization.activeOrPendingBankAccounts;
    let limit = this.getLimitByFeatureCode('multi_accounts');
    // remove 'limit > -1' once we migrated to modular pricing (when `feature--boolean-mp-epic2b` removed)
    return limit > -1 && limit !== null && length >= limit;
  }

  /**
   * Has the current subscription max number of team members been reached?
   *
   * @public
   * @method hasReachedUserLimit
   *
   * @return {Boolean}
   */
  get hasReachedUserLimit() {
    let { organization } = this.organizationManager;
    let userLimit = this.getLimitByFeatureCode('additional_users');
    return userLimit !== null && organization.membershipsCountingTowardsPlanLimitCount >= userLimit;
  }

  /**
   * Has the current subscription max number of accountants been reached?
   *
   * @public
   * @method hasReachedAccountantLimit
   *
   * @return {Boolean}
   */
  get hasReachedAccountantLimit() {
    let { organization } = this.organizationManager;
    let accountantLimit = this.getLimitByFeatureCode('accountant_access');
    return (
      accountantLimit !== null &&
      organization.accountantsCountingTowardsPlanLimitCount >= accountantLimit
    );
  }

  /**
   * Do we need to upgrade in order to invite more members?
   *
   * @public
   * @method planUpgradeIsNeeded
   *
   * @return {Boolean}
   */
  get planUpgradeIsNeeded() {
    assert('currentPricePlan should have been initialized first', this.currentPricePlan);

    return (
      this.hasReachedUserLimit &&
      this.hasReachedAccountantLimit &&
      this.abilities.cannot('create paid members members')
    );
  }

  get planPriceWithVAT() {
    let recurrence = this.currentSubscription.recurrence;

    if (recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL) {
      return this.currentSubscription.pricePlanAnnualPriceVatIncluded;
    }
    return this.currentSubscription.pricePlanMonthlyPriceVatIncluded;
  }

  @cached
  get directDebitCollectionFee() {
    return this.intl.formatNumber(this.options.directDebitCollectionSend.value, {
      style: 'currency',
      currency: 'EUR',
    });
  }
}
