/* eslint-disable ember/require-computed-macros */
import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const MAX_TRANSFER_AMOUNT_WITHOUT_DOCUMENT = 30000;

const RequestMultiTransferTransferValidations = buildValidations(
  {
    beneficiaryName: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
    ],
    localAmount: [validator('ds-error')],
    amount: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
    ],
    attachments: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.{amount,attachmentCount}', function () {
          return !(
            Number(this.model.amount) >= MAX_TRANSFER_AMOUNT_WITHOUT_DOCUMENT &&
            this.model.attachmentCount === 0
          );
        }),
        messageKey: 'validations.errors.uploaded_attachment',
      }),
    ],
    activityTag: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
    ],
    reference: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        messageKey: 'validations.errors.presence',
      }),
      validator('length', {
        max: 99,
      }),
    ],

    beneficiaryIban: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.{beneficiaryIban,beneficiaryBic}', function () {
          return Boolean(this.model.beneficiaryIban) && Boolean(this.model.beneficiaryBic);
        }),
        messageKey: 'validations.errors.presence',
      }),
    ],

    beneficiaryEmail: [
      validator('ds-error'),
      validator('presence', {
        presence: true,
        disabled: computed('model.notifyByEmail', function () {
          return !this.model.notifyByEmail;
        }),
        messageKey: 'validations.errors.presence',
      }),
      validator('format', {
        type: 'email',
        disabled: computed('model.notifyByEmail', function () {
          return !this.model.notifyByEmail;
        }),
        minTldLength: 2,
        messageKey: 'validations.errors.email',
      }),
    ],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default RequestMultiTransferTransferValidations;
