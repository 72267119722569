import ApplicationSerializer from 'qonto/serializers/application';

export default class extends ApplicationSerializer {
  serialize(snapshot, options) {
    let json = super.serialize(snapshot, options);

    delete json.approver_id;
    delete json.initiator_id;
    delete json.processed_at;
    delete json.created_at;
    delete json.reference;
    delete json.request_type;
    delete json.status;

    return json;
  }
}
