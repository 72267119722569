import Service, { service } from '@ember/service';

import { isDevelopingApp, macroCondition } from '@embroider/macros';
import { ZENDESK_KEYS } from '@qonto/ui-kit/constants/zendesk-keys';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { loadScript } from 'qonto/utils/load-script';

export default class ZendeskWidgetService extends Service {
  @service segment;
  @service sentry;
  @service sessionManager;
  @service zendeskLocalization;
  @service userManager;
  @service webviewManager;

  get isProduction() {
    return macroCondition(!isDevelopingApp()) ? true : false;
  }

  enableProductionAPIKeysInTesting = false;

  get shouldMountSandBox() {
    return ENV.deployTarget !== 'production' && !this.enableProductionAPIKeysInTesting;
  }

  errorHandler(message, error) {
    if (this.isProduction) {
      this.sentry.captureException(new Error(message), { extra: { error } });
    } else {
      // eslint-disable-next-line no-console
      console.error(message, error);
    }
  }

  get isMounted() {
    return window.zE !== undefined;
  }

  open = () => {
    if (this.isMounted) {
      window.zE('messenger', 'open');
    }
  };

  close = () => {
    if (this.isMounted) {
      window.zE('messenger', 'close');
    }
  };

  #loadAndAuthenticate = async () => {
    try {
      await this.#loadScript(this.zendeskLocalization.organizationCountry);
      this.localeChange(this.zendeskLocalization.userLocale);
      this.#eventTracking();
      let jwt = this.userManager?.currentUser?.zendesk?.messaging_widget_jwt;
      if (this.sessionManager.isAuthenticated) this._authenticate(jwt);
    } catch (error) {
      this.errorHandler('Error loading and authenticating the Zendesk widget:', error);
    }
  };

  localeChange = userLocale => {
    if (this.isMounted) {
      window.zE('messenger:set', 'locale', this.zendeskLocalization.calcZendeskLocale(userLocale));
    }
  };

  hide = () => {
    if (this.isMounted) {
      window.zE('messenger', 'hide');
    }
  };

  show = () => {
    if (this.isMounted) {
      window.zE('messenger', 'show');
    }
  };

  unmount = () => {
    if (this.isMounted) {
      window.zE('messenger', 'hide');
      window.zE('messenger', 'logoutUser');
    }

    window.zE = undefined;
    window.zEmbed = undefined;
    window.zEACLoaded = undefined;
    window.zESettings = undefined;

    document.getElementById('ze-snippet')?.remove();

    sessionStorage.setItem('zendesk-widget-opened', 'false');
  };

  enableTesting = false;

  selectCountry = async orgCountry => {
    this.unmount();

    if ((!this.isProduction && !this.enableTesting) || this.webviewManager.isWebview) return;

    if (this.zendeskLocalization.organizationCountry === orgCountry && this.isMounted) return;
    try {
      this.zendeskLocalization.organizationCountry = orgCountry;
      await this.#loadAndAuthenticate();
    } catch (error) {
      this.errorHandler('Error changing the Zendesk widget country:', error);
    }
  };

  #loadScript = async orgCountry => {
    let zendeskKey = this.shouldMountSandBox
      ? ZENDESK_KEYS.__sandbox__
      : ZENDESK_KEYS[orgCountry] || ZENDESK_KEYS[this.ZENDESK_DEFAULTS.UNAUTH_FALLBACK_COUNTRY];

    try {
      await loadScript(`https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`, {
        scriptId: 'ze-snippet',
      });
    } catch (error) {
      this.errorHandler('Error loading the Zendesk script:', error);
    }
  };

  _authenticate = jwt => {
    if (!jwt) {
      this.errorHandler('Zendesk widget: messaging_widget_jwt is undefined.');
      return;
    }
    if (!this.isMounted) {
      this.errorHandler('Zendesk Embed (zE) is not defined.');
      return;
    }

    window.zE('messenger', 'loginUser', callback => {
      callback(jwt, response => {
        if (response && response.error) {
          this.errorHandler('Authentication failed:', response.error);
        }
      });
    });
  };

  #eventTracking = () => {
    if (this.isMounted) {
      // this.segment.track('zendesk_web-widget_started', { page_url: window.location.href });
      window.zE('messenger:on', 'open', () => {
        if (!sessionStorage.getItem('zendesk-widget-opened')) {
          sessionStorage.setItem('zendesk-widget-opened', 'true');
        }
      });
    }
  };
}
