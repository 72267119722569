import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

import { DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES } from 'qonto/constants/direct-debit-subscriptions';

const DirectDebitSubscriptionValidations = buildValidations({
  'amount.value': [
    validator('presence', {
      presence: true,
      messageKey: 'validations.errors.blank',
    }),
    validator('number', {
      allowString: true,
      gt: 0,
      messageKey: 'validations.errors.sdd.amount-invalid-float',
    }),
    validator('number', {
      allowString: true,
      lte: 150000,
      messageKey: 'validations.errors.sdd.amount-too-big',
    }),
  ],
  initialCollectionDate: [
    validator('presence', {
      presence: true,
      messageKey: 'validations.errors.blank',
    }),
  ],
  reference: [
    validator('presence', {
      presence: true,
      messageKey: 'validations.errors.blank',
    }),
  ],
  scheduleCustomInterval: [
    validator('presence', {
      presence: true,
      disabled: computed('model.{scheduleType,type}', function () {
        return this.model.scheduleType !== DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM;
      }),
      messageKey: 'validations.errors.blank',
    }),
    validator('number', {
      gt: 0,
      allowString: true,
      disabled: computed('model.{scheduleType,type}', function () {
        return this.model.scheduleType !== DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM;
      }),
      messageKey: 'validations.errors.sdd.amount-invalid',
    }),
  ],
  scheduleTotalCollectionCount: [
    validator('presence', {
      presence: true,
      disabled: computed('model.{isCustomDuration,isRecurring}', function () {
        return !(this.model.isRecurring && this.model.isCustomDuration);
      }),
      messageKey: 'validations.errors.blank',
    }),
    validator('number', {
      gt: 0,
      allowString: true,
      disabled: computed('model.{isCustomDuration,isRecurring}', function () {
        return !(this.model.isRecurring && this.model.isCustomDuration);
      }),
      messageKey: 'validations.errors.sdd.amount-invalid',
    }),
  ],
});

export default DirectDebitSubscriptionValidations;
