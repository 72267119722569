class Table {
  constructor(rules) {
    this.rules = rules;
  }

  evaluate(evaluator) {
    let rule = this.rules.find(rule => rule.match(evaluator));

    if (rule) {
      return rule.actions;
    }
  }
}

class Rule {
  constructor(name, conditionsValues, actions) {
    this.name = name;
    this.conditionsValues = conditionsValues;
    this.actions = actions;
  }

  match(evaluator) {
    return !this.conditionsValues.some(([condition, value]) => {
      return evaluator[condition] !== value;
    });
  }
}

/**
 * Parses the CSV passed as a parameter and returns Table instance
 *
 * @param {string} file the string of csv to be parsed
 * @returns Table
 */
function fromCsv(file) {
  if (!file) {
    throw new Error('missing file');
  }

  // The split('\n') splits the file in rows
  // the split(';') splits each row into cells
  let rows = file.split('\n').map(row => row.trim().split(';'));

  let header = parseHeader(rows.shift());

  let firstCell = rows.shift();

  // The row right after the header should be `# conditions`
  if (!isRow('conditions', firstCell)) {
    throw new Error(`conditions row expected, got: ${firstCell}`);
  }

  let conditions = [];
  let actions = [];

  let isTraversingConditions = true;

  while (rows.length > 0) {
    let row = rows.shift();

    if (isRow('actions', row)) {
      isTraversingConditions = false;
    } else if (typeof row[0] === 'string' && row[0].length > 0) {
      if (isTraversingConditions) {
        conditions.push(row);
      } else {
        actions.push(row);
      }
    } else {
      throw new Error(`unexpected row: ${row}`);
    }
  }

  if (conditions.length === 0) {
    throw new Error('no rule found');
  }

  if (actions.length === 0) {
    throw new Error('no action found');
  }

  // ensuring the header, the conditions and actions have the same number of columns
  if (
    conditions.some(condition => condition.length !== header.length + 1) ||
    actions.some(action => action.length !== header.length + 1)
  ) {
    throw new Error('header, conditions and actions should have the same number of columns');
  }

  return new Table(buildRules(header, conditions, actions));
}

/**
 * Takes a value and trims the whitespace around it
 *
 * @param {string} value the value to be trimmed
 * @returns string or null
 */
function trimValue(value) {
  if (value === null || value === undefined || value === '') {
    return null;
  } else {
    return value.trim();
  }
}

/**
 * Transform a given value 'Y', 'N', '-' into a boolean or throw an exception if no match has been found.
 *
 * @param {string} value the condition value in the DT (can be 'Y', 'N' or '-')
 * @returns boolean, null or string
 */
function formatValue(value) {
  value = value.trim();
  switch (value) {
    case 'Y':
      return true;
    case 'N':
      return false;
    case '-':
      return null;
    case '':
      throw new Error('empty condition value');
    default:
      return value;
  }
}

/**
 * Returns true if the passed row is the <type> row, false otherwise
 *
 * @param {string} type the type of the row to be evaluated
 * @param {string} row the row to be evaluated
 * @returns boolean
 */
function isRow(type, row) {
  return row[0].toLowerCase() === `# ${type}`;
}

/**
 * Parses the array from sting to array to be usable
 *
 * @param {Array<string>} header the header row of the table
 * @returns an array representing the header
 */
function parseHeader(header) {
  header = header.map(value => trimValue(value));

  // In the CSV export the header has an empty cell (above the # conditions # actions lines)
  // We check that it's present, and shift the whole header to only have the numbers (0, 1, 2 ...)
  if (header[0] !== null) {
    throw new Error('first header row value should be null');
  }
  header.shift(); // Get rid of non rule (name) cell
  return header;
}

/**
 * Formats and trims the 'useless' columns of conditions and actions in the DT
 * and returns them as an array of Rule objects
 *
 * @param {string} header the header row of the DT
 * @param {string} conditions the conditions row of the DT
 * @param {string} actions the actions row of the DT
 * @returns Array<Rule>
 */
function buildRules(header, conditions, actions) {
  /**
   * Example values for conditions and actions
   *
   * let conditions = [["display_field","N","Y","-","-"],["input_submitted","-","N","Y","Y"],["validate_field","-","-","Y","N"]]
   * let actions = [["display_field","","X","",""],["validate_field","","","X",""]]
   */

  let conditionsNames = conditions.map(c => c.shift());
  let actionsNames = actions.map(a => a.shift());

  /**
   * let conditions = [["N","Y","-","-"],["-","N","Y","Y"],["-","-","Y","N"]]
   * let actions = [["","X","",""],["","","X",""]]
   *
   * let conditionsNames = ["display_field","input_submitted","validate_field"]
   * let actionsNames = [["display_field","validate_field"]
   */

  let rules = [];

  for (let i = 0; i < conditions[0].length; i++) {
    let name = header[i];

    let conditionsValues = conditions
      .map((condition, idx) => [conditionsNames[idx], formatValue(condition[i])])
      .filter(([, value]) => value !== null);

    let matchingActions = actions
      .map((action, idx) => trimValue(action[i]) && actionsNames[idx])
      .filter(action => action !== null);

    /**
     * let conditionsValues = [["input_submitted",true],["validate_field",true]]
     * let matchingActions = ["validate_field"]
     */

    rules.push(new Rule(name, conditionsValues, matchingActions));
  }

  return rules;
}

export default fromCsv;
