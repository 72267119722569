import Service, { service } from '@ember/service';

import { USER_ACTIONS_STATUS, USER_ACTIONS_TYPE } from 'qonto/constants/user-actions';

import { discoveryCards } from './product-discovery/discovery';
import { requiredCards } from './product-discovery/required';
import { setupCards } from './product-discovery/setup';

export default class ProductDiscoveryService extends Service {
  @service organizationManager;
  @service subscriptionManager;
  @service periodicUpdate;
  @service intl;
  @service router;
  @service store;
  @service sentry;

  get isProductDiscoverySystemFeatureEnabled() {
    let { organization, membership } = this.organizationManager;
    let { hasProductDiscoverySystemFeature: organizationHasFeature } = organization;
    let { hasProductDiscoverySystemFeature: membershipHasFeature } = membership;
    return organizationHasFeature && membershipHasFeature;
  }

  setupCardConfigs() {
    return setupCards({
      intl: this.intl,
      store: this.store,
      organization: this.organizationManager.organization,
      remainingDaysOfTrial: this.subscriptionManager.currentSubscription?.activeTrialRemainingDays,
    });
  }

  requiredCardConfigs() {
    return requiredCards({ intl: this.intl, organization: this.organizationManager.organization });
  }

  discoveryCardConfigs() {
    return discoveryCards({
      intl: this.intl,
      router: this.router,
      store: this.store,
      organization: this.organizationManager.organization,
    });
  }

  /**
   * Fetches the user-actions using the store.query method. Unloads the records that are not in the response.
   * It also adds custom actions not coming from LaunchDarkly.
   */
  async fetchUserActions({ updateProcess }) {
    let {
      organization: { isSuspended, isDeactivated },
    } = this.organizationManager;

    if (isSuspended || isDeactivated) {
      return;
    }

    let latestRecords = await this.store.query('user-action', {});
    let existingRecords = this.store.peekAll('user-action');
    let staleRecords = existingRecords.filter(
      record => !latestRecords.find(r => r.name === record.name)
    );
    staleRecords.forEach(record => record.unloadRecord());

    // Custom actions coming from the frontend logic
    this.#maybeAddPeriodicUpdateAction({ updateProcess });
  }

  #maybeAddPeriodicUpdateAction({ updateProcess }) {
    if (this.periodicUpdate.shouldDisplayPeriodicUpdateAction({ updateProcess })) {
      this.store.createRecord('user-action', {
        name: 'kyc-kyb-periodic-update-action',
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.REQUIRED,
        membership: this.organizationManager.membership,
      });
    }
  }

  getCardConfigFn(type, name) {
    if (type === USER_ACTIONS_TYPE.SETUP) {
      return this.setupCardConfigs()[name];
    } else if (type === USER_ACTIONS_TYPE.REQUIRED) {
      return this.requiredCardConfigs()[name];
    } else if (type === USER_ACTIONS_TYPE.DISCOVERY) {
      return this.discoveryCardConfigs()[name];
    }
  }

  visibleUserActions() {
    return this.store
      .peekAll('user-action')
      .filter(({ status }) => status !== USER_ACTIONS_STATUS.DISMISSED)
      .filter(({ membership: { id } }) => id === this.organizationManager.membership.id)
      .reduce((acc, actionRecord) => {
        let cardConfigFn = this.getCardConfigFn(actionRecord.type, actionRecord.name);
        if (!cardConfigFn) {
          this.sentry.captureMessage(
            `Product Discovery - User action with name: "${actionRecord.name}" and type: "${actionRecord.type}" does not exist in qonto-js`
          );
          return acc;
        } else {
          let cardConfig = cardConfigFn();
          return [
            ...acc,
            {
              ...cardConfig,
              name: actionRecord.name,
              type: actionRecord.type,
              status: actionRecord.status,
              actionRecord,
            },
          ];
        }
      }, []);
  }

  hasAction(name) {
    return Boolean(this.visibleUserActions().find(action => action.name === name));
  }
}
