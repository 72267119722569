import { assert } from '@ember/debug';

// These characters do not get URLEncoded: ~!*()-_'.
const ARRAY_SEPARATOR = '.';
const KEY_VALUE_SEPARATOR = '~';

export function serializeArrayQP(arr, separator = ARRAY_SEPARATOR) {
  if (!arr) {
    return '';
  }

  assert('Expected arg to be an array', Array.isArray(arr));

  // Assertions that are not removed from production build.
  // This is because production DB may have values that are not detected in development,
  // which means we need to check them in production runtime.
  arr.forEach((item, i) => {
    if (typeof item !== 'string') {
      throw new Error(`Array serialization failed: expected item at index ${i} to be a string`);
    }

    if (item.includes(separator)) {
      throw new Error(
        `Array serialization failed: expected item "${item}" at index ${i} not to contain serparator "${separator}"`
      );
    }
  });

  return arr.join(separator);
}

export function deserializeArrayQP(str, separator = ARRAY_SEPARATOR) {
  // Need to take care of this JS glitch: ''.split() // => ['']
  return str ? str.split(separator) : [];
}

export function serializeObjectQP(
  obj,
  arraySeparator = ARRAY_SEPARATOR,
  keyValueSeparator = KEY_VALUE_SEPARATOR
) {
  if (!obj) {
    return '';
  }

  assert('Expected arg to be a plain object', typeof obj === 'object' && !(obj instanceof Date));

  let pairs = Object.entries(obj);

  // Assertions that are not removed from production build.
  // This is because production DB may have values that are not detected in development,
  // which means we need to check them in production runtime.
  let substrings = pairs.map(([key, value]) => {
    if (typeof value !== 'string' && value !== null && value !== undefined) {
      throw new Error(
        `Object serialization failed: expected value at key ${key} to be a string, null or undefined`
      );
    }

    if (key.includes(arraySeparator)) {
      throw new Error(
        `Object serialization failed: expected key "${key}" not to contain serparator "${arraySeparator}"`
      );
    }

    if (key.includes(keyValueSeparator)) {
      throw new Error(
        `Object serialization failed: expected key "${key}" not to contain serparator "${keyValueSeparator}"`
      );
    }

    if (typeof value === 'string' && value.includes(arraySeparator)) {
      throw new Error(
        `Object serialization failed: expected value "${value}" at key "${key}" not to contain serparator "${arraySeparator}"`
      );
    }

    if (typeof value === 'string' && value.includes(keyValueSeparator)) {
      throw new Error(
        `Object serialization failed: expected value "${value}" at key "${key}" not to contain serparator "${keyValueSeparator}"`
      );
    }

    if (value === null || value === undefined) {
      return key;
    }

    return `${key}${keyValueSeparator}${value}`;
  });

  return substrings.join(arraySeparator);
}

export function deserializeObjectQP(
  str,
  arraySeparator = ARRAY_SEPARATOR,
  keyValueSeparator = KEY_VALUE_SEPARATOR
) {
  let substrings = str ? str.split(arraySeparator) : [];

  return substrings.reduce((result, substring) => {
    let [key, value] = substring.split(keyValueSeparator);

    result[key] = value === null || value === undefined ? null : value;

    return result;
  }, {});
}

/**
 * When we pass an object or an array to the query params, it will be stringified.
 * Afterward, if we try to access the query params object with the router or transition object,
 * these value will still be stringified.
 * The purpose of this function is to parse every direct property's value of the query params object
 * to ensure they have their right type.
 */
export function parseObjectValuesQP(obj) {
  assert(
    'Expected arg to be a plain object',
    typeof obj === 'object' && !(obj instanceof Date) && !Array.isArray(obj)
  );

  return Object.entries(obj).reduce((acc, [key, value]) => {
    try {
      acc[key] = JSON.parse(value);
    } catch {
      acc[key] = value;
    }
    return acc;
  }, {});
}
