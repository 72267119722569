import CURRENCIES from 'qonto/constants/currencies';
import {
  getAllowedDecimalPlaces,
  getCorrespondingAmountInterval,
  getCorrespondingAmountPlaceholder,
} from 'qonto/utils/currency';

/**
 * Formats the given amount as a number with the specified precision.
 *
 * @param options - The options object.
 * @param options.amount - The amount to be formatted.
 * @param options.precision - The number of decimal places to include.
 * @returns The formatted amount as a number, or null if the input amount is null.
 *
 * @example
 *
 * ```ts
 * formatAmountToNumber({ amount: 12.345, precision: 2 }); // 12.35
 * formatAmountToNumber({ amount: '12.345', precision: 2 }); // 12.35
 * formatAmountToNumber({ amount: 12, precision: 2 }); // 12.00
 * formatAmountToNumber({ amount: null, precision: 2 }); // null
 * formatAmountToNumber({ amount: '', precision: 2 }); // null
 * ```
 */
export function formatAmountToNumber({
  amount,
  precision = 2,
}: {
  amount: number | string;
  precision: number;
}): number | null {
  const formattedAmountAsString = formatAmountToString({ amount, precision });
  if (formattedAmountAsString === null || !formattedAmountAsString.trim()) return null;
  return parseFloat(formattedAmountAsString);
}

/**
 * Formats the given amount as a string with the specified precision.
 *
 * @param options - The options object.
 * @param options.amount - The amount to format. It can be a number or a string representation of a number.
 * @param options.precision - The number of decimal places to use. Default is 2.
 * @returns The formatted amount as a string, or null if the input amount is null.
 *
 * @example
 *
 * ```ts
 * formatAmountToString({ amount: 12.345, precision: 2 }); // '12.35'
 * formatAmountToString({ amount: '12.345', precision: 2 }); // '12.35'
 * formatAmountToString({ amount: 12, precision: 2 }); // '12.00'
 * formatAmountToString({ amount: null, precision: 2 }); // null
 * formatAmountToString({ amount: '', precision: 2 }); // ''
 * ```
 */
export function formatAmountToString({
  amount,
  precision = 2,
}: {
  amount: number | string;
  precision: number;
}): string | null {
  if (amount === null || amount === undefined) return null;
  const amountAsString = amount.toString();
  if (!amountAsString.trim()) return '';
  return parseFloat(amountAsString).toFixed(precision);
}

/**
 * Formats an amount without the currency symbol.
 *
 * @param options - An object containing the amount and currency.
 * @param options.amount - The amount to be formatted.
 * @param options.currency - The currency of the amount.
 * @param locale - The locale to use for the number formatting.
 * @returns The formatted amount without the currency symbol.
 *
 * @example
 *
 * ```ts
 * formatAmountWithoutCurrency({ amount: 1234.56, currency: 'USD' }, 'en-US'); // '1,234.56'
 * ```
 */
export function formatAmountWithoutCurrency(
  { amount }: { amount: number },
  locale: string
): string {
  const numberFormatter = new Intl.NumberFormat(locale);
  return numberFormatter
    .formatToParts(amount)
    .filter(({ type }) => type !== 'currency')
    .reduce((acc, { value }) => acc + value, '');
}

/**
 * Retrieves properties related to the amount for a specific currency.
 *
 * @param currencyCode - The ISO 4217 currency code. Defaults to 'EUR' if not provided.
 * @param locale - The locale to retrieve the properties for. If not provided, the default locale ('en-US') is used.
 * @returns An object containing the following properties:
 * - `decimalSeparator` - The decimal separator used in the locale.
 * - `groupSeparator` - The group separator used in the locale.
 * - `numberOfDecimals` - The maximum number of decimal places allowed for the currency.
 * - `placeholder` - A string to be used as a placeholder for the amount input field for the currency.
 * - `step` - The interval at which the amount for the currency should be stepped up or down.
 *
 * @example
 *
 * ```ts
 * getAmountProperties('USD'); // Returns { numberOfDecimals: 2, placeholder: '0,00', step: 0.01 }
 * ```
 */
export function getAmountProperties(
  currencyCode = CURRENCIES.default,
  locale = 'en-US'
): {
  decimalSeparator: string;
  groupSeparator: string;
  numberOfDecimals: number;
  placeholder: string;
  step: number;
} {
  const { decimalSeparator, groupSeparator } = getLocaleNumberConfig(locale);
  return {
    decimalSeparator,
    groupSeparator,
    numberOfDecimals: getAllowedDecimalPlaces(currencyCode),
    placeholder: getCorrespondingAmountPlaceholder(currencyCode, decimalSeparator),
    step: getCorrespondingAmountInterval(currencyCode),
  };
}

/**
 * Extracts the group and decimal separators from the locale.
 *
 * @param locale - The locale for which to retrieve the number configuration.
 * @returns An object containing the group and decimal separators used in the provided locale.
 *
 * @example
 *
 * ```ts
 * getLocaleNumberConfig('en-US'); // { groupSeparator: ',', decimalSeparator: '.' }
 * ```
 */
export const getLocaleNumberConfig = (
  locale: string
): { groupSeparator: string; decimalSeparator: string } => {
  const numberFormatter = new Intl.NumberFormat(locale);

  return numberFormatter.formatToParts(1000.1).reduce(
    (acc, { value, type }) => {
      if (type === 'group') {
        return { ...acc, groupSeparator: value };
      }
      if (type === 'decimal') {
        return { ...acc, decimalSeparator: value };
      }

      return acc;
    },
    { groupSeparator: '', decimalSeparator: '' }
  );
};

/**
 * Parses a formatted amount string into a float number.
 *
 * @param amount - The formatted amount string to be parsed.
 * @param options - An object containing the decimal separator and group separator used in the formatted amount string.
 * @param options.decimalSeparator - The decimal separator used in the formatted amount string.
 * @param options.groupSeparator - The group separator used in the formatted amount string.
 * @returns The parsed amount as a floating number.
 *
 * @example
 *
 * ```ts
 * parseFormattedAmount('1.234,56', { decimalSeparator: ',', groupSeparator: '.' }); // 1234.56
 * ```
 */
export function parseFormattedAmount(
  amount: string,
  { decimalSeparator, groupSeparator }: { decimalSeparator: string; groupSeparator: string }
): number {
  return parseFloat(amount.replaceAll(groupSeparator, '').replace(decimalSeparator, '.'));
}
