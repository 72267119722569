import Service, { service } from '@ember/service';

import * as prismic from '@prismicio/client';

import { prismicToken, repoName } from 'qonto/constants/prismic';

const MAX_PRISMIC_PAGE_SIZE = 100;

const formattedUserLocaleLookup = {
  en: 'en-us',
  es: 'es-es',
  fr: 'fr-fr',
  de: 'de-de',
  it: 'it-it',
};

const createPrismicClient = () => {
  let endpoint = prismic.getRepositoryEndpoint(repoName);
  return prismic.createClient(endpoint, { accessToken: prismicToken });
};

export default class PrismicService extends Service {
  @service intl;

  client = createPrismicClient();

  get lang() {
    return formattedUserLocaleLookup[this.intl.locale] || formattedUserLocaleLookup['en'];
  }

  get prismicLibrary() {
    return prismic;
  }

  async get(options) {
    let pageSize = options.pageSize || MAX_PRISMIC_PAGE_SIZE;

    return await this.client.get({
      ...options,
      pageSize,
      lang: this.lang,
    });
  }

  async getByUID(model, uid, options) {
    return await this.client.getByUID(model, uid, {
      ...options,
      lang: this.lang,
    });
  }
}
