import { computed } from '@ember/object';

import { buildValidations, validator } from 'ember-cp-validations';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

const VAT_VALIDATIONS = {
  FR: /^\d{13}$/,
  IT: /^[a-zA-Z0-9]{16}$/,
  US: /^\d{9}$/,
};

const KycKybUpdateMembershipChangeRequestValidations = buildValidations(
  {
    addressCity: [validator('ds-error'), validator('presence', true)],
    addressCountry: [validator('ds-error'), validator('presence', true)],
    addressFirstLine: {
      validators: [
        validator('ds-error'),
        validator('presence', true),
        validator('length', { min: 2 }),
      ],
    },
    addressZipCode: {
      validators: [
        validator('ds-error'),
        validator('presence', true),
        validator('length', { min: 2 }),
      ],
    },
    birthCity: [
      validator('ds-error'),
      validator('presence', true),
      validator('format', {
        regex: /\w+/,
        messageKey: 'validations.errors.blank',
      }),
    ],
    birthdate: [
      validator('ds-error'),
      validator('presence', true),
      validator('date', {
        after: '1/1/1900',
        // Users taking less than 4 days to complete an invitation
        before: new Date(Date.now() - 4 * 24 * 60 * 60 * 1000),
        format: 'DD/MM/YYYY',
        messageKey: 'validations.errors.invalid_date',
      }),
    ],

    corporateOfficer: [validator('ds-error'), validator('presence', true)],
    firstName: [validator('ds-error'), validator('presence', true)],
    lastName: [validator('ds-error'), validator('presence', true)],
    gender: [validator('ds-error'), validator('presence', true)],
    legalRepresentative: [validator('ds-error'), validator('presence', true)],
    nationality: [validator('ds-error'), validator('presence', true)],
    taxCountry: [
      validator('ds-error'),
      validator('dependent', { on: ['ubo'] }),
      validator('tax-country-presence', {
        messageKey: 'validations.errors.blank',
      }),
    ],
    taxIdentificationNumber: [
      validator('tin-format', {
        taxCountryFormat: computed('model.taxCountry', function () {
          return VAT_VALIDATIONS[this.model.taxCountry];
        }),
        messageKey: {
          wrongFormat: 'organizations.profile.owner-form.fiscal-info.error-tin',
          fieldIsMissing: 'validations.errors.blank',
        },
      }),
    ],
    ubo: [validator('ds-error'), validator('presence', true)],
    taxPayerOutsideLegalCountry: [validator('ds-error'), validator('presence', true)],
    usTaxPayer: [validator('ds-error'), validator('presence', true)],
  },
  {
    debounce: DEBOUNCE_MS,
  }
);

export default KycKybUpdateMembershipChangeRequestValidations;
