import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { restartableTask, task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import {
  BANK_CONNECTION_STATUS,
  CATEGORIES,
  UNDISPLAYED_INTEGRATION,
} from 'qonto/constants/connect';
import { SLACK_OAUTH_STATE_KEY } from 'qonto/constants/hub';
import SOLUTION_INSTANCE_STATUS from 'qonto/constants/solution-instance';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ConnectManager extends Service {
  @service prismic;
  @service organizationManager;
  @service segment;
  @service router;
  @service modals;
  @service toastFlashMessages;
  @service intl;
  @service sentry;

  authRouteName = 'settings.connect-hub.applications.hub-application.authentication';
  settingsRouteName = 'settings.connect-hub.applications.hub-application.details.settings';
  setupRouteName = 'settings.connect-hub.applications.hub-application.setup';

  async getIntegrations({
    includeBeta = false,
    bucketId,
    categoryId,
    stakeholderId,
    collectionSlug,
    query,
    page = 1,
    bankMarket,
    orderings = {
      field: 'my.connect_integrations.uid',
    },
  } = {}) {
    let searchParams = {
      page,
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'connect_integrations'),
        this.prismic.prismicLibrary.predicate.at('document.tags', [
          `market_${this.organizationManager.organization.legalCountry.toLowerCase()}`,
        ]),
      ],
      orderings,
      fetchLinks: [
        'connect_categories.name',
        'connect_stakeholders.name',
        'connect_types.name',
        'connect_qualities.name',
        'connect_quality_domains.name',
      ],
    };

    if (bucketId) {
      searchParams.predicates.push(
        this.prismic.prismicLibrary.predicate.at('my.connect_integrations.buckets.bucket', bucketId)
      );
      searchParams.fetchLinks.push('buckets.bucket');
    }

    if (query) {
      searchParams.predicates.push(
        this.prismic.prismicLibrary.predicate.fulltext('document', query)
      );
    }

    if (!variation('feature--boolean-account-aggregation-marketplace')) {
      let categories = await this.getAllCategories();
      let bankCategory = categories.find(category => category.slug === CATEGORIES.BANKS);

      if (bankCategory)
        searchParams.predicates.push(
          this.prismic.prismicLibrary.predicate.not(
            'my.connect_integrations.category',
            bankCategory.id
          )
        );
    }

    if (categoryId) {
      searchParams.predicates.push(
        this.prismic.prismicLibrary.predicate.at('my.connect_integrations.category', categoryId)
      );
    }

    if (stakeholderId) {
      searchParams.predicates.push(
        this.prismic.prismicLibrary.predicate.at(
          'my.connect_integrations.stakeholders.stakeholder',
          stakeholderId
        )
      );
    }

    if (collectionSlug) {
      switch (collectionSlug) {
        case 'ebics': {
          let getAllCollections = await this.getAllCollections();
          let collection = getAllCollections.find(({ slug }) => slug === collectionSlug);

          searchParams.predicates.push(
            this.prismic.prismicLibrary.predicate.at(
              'my.connect_integrations.types.type',
              collection.id
            )
          );
          break;
        }
        case 'partnerships':
          searchParams.predicates.push(
            this.prismic.prismicLibrary.predicate.at(
              'my.connect_integrations.integration_type',
              'partnerships'
            )
          );
          break;

        case 'integrations':
          searchParams.predicates.push(
            this.prismic.prismicLibrary.predicate.not(
              'my.connect_integrations.integration_type',
              'partnerships'
            )
          );
      }
    }

    if (bankMarket) {
      searchParams.predicates.push(
        this.prismic.prismicLibrary.predicate.at(
          `my.connect_integrations.bank_is_popular_in_${bankMarket}`,
          true
        )
      );
    }

    if (!includeBeta) {
      searchParams.predicates.push(
        this.prismic.prismicLibrary.predicate.at(
          'my.connect_integrations.beta__webapp__website__mobile_',
          false
        )
      );
    }

    let integrations = await this.prismic.get(searchParams);
    let transformedResults = integrations.results.map(integration => this.transform(integration));

    integrations.results = transformedResults;

    return integrations;
  }

  async getFeaturedIntegrations() {
    let landingIndex = `my.connect_integrations.landing_section_index_${this.organizationManager.organization.legalCountry.toLowerCase()}`;
    let searchParams = {
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'connect_integrations'),
        this.prismic.prismicLibrary.predicate.at('document.tags', [
          `market_${this.organizationManager.organization.legalCountry.toLowerCase()}`,
        ]),
        this.prismic.prismicLibrary.predicate.not(landingIndex, UNDISPLAYED_INTEGRATION),
      ],
      fetchLinks: ['connect_categories.name', 'connect_stakeholders.name', 'connect_types.name'],
    };

    let integrations = await this.prismic.get(searchParams);
    let transformedResults = integrations.results.map(integration => this.transform(integration));

    integrations.results = transformedResults;

    return integrations;
  }

  async getAllCategories() {
    let categories = await this.prismic.get({
      predicates: [this.prismic.prismicLibrary.predicate.at('document.type', 'connect_categories')],
    });

    let results = this.flattenList(
      categories.results.filter(result => {
        return Object.values(CATEGORIES).includes(result.data.slug);
      })
    );

    return results.sort((a, b) => (a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1));
  }

  async getAllStakeholders() {
    let stakeholders = await this.prismic.get({
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'connect_stakeholders'),
      ],
    });

    let results = this.flattenList(stakeholders.results);

    return results.sort((a, b) => (a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1));
  }

  async getAllCollections() {
    let collections = await this.prismic.get({
      predicates: [this.prismic.prismicLibrary.predicate.at('document.type', 'connect_types')],
    });

    let results = this.flattenList(collections.results);

    return results.sort((a, b) => (a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1));
  }

  async getIntegrationBuckets({ location } = {}) {
    let query = {
      predicates: [this.prismic.prismicLibrary.predicate.at('document.type', 'connect_bucket')],
    };

    if (location) {
      query.predicates.push(
        this.prismic.prismicLibrary.predicate.at(
          'my.connect_bucket.locations.location_that_display_the_bucket',
          location
        )
      );
    }

    let response = await this.prismic.get(query);
    let transformedResults = response.results.map(bucket => this.transformBucket(bucket));

    response.results = transformedResults;

    return response;
  }

  async getIntegration(slug) {
    assert('An integration slug is required', slug);

    let integrations = await this.prismic.get({
      predicates: [
        // eslint-disable-next-line ember/no-array-prototype-extensions
        this.prismic.prismicLibrary.predicate.any('my.connect_integrations.slug', [slug]),
      ],
      fetchLinks: 'connect_categories.name',
    });

    let integration = integrations.results?.[0];

    if (!integration) {
      return null;
    }

    return this.transform(integration);
  }

  showInstalledButton(model) {
    let { hubConnection, solutionInstance, hrisConnection, bankConnection } = model;

    return (
      (hubConnection?.externalService && !hubConnection?.isDeleted) ||
      bankConnection?.status === BANK_CONNECTION_STATUS.SYNCHRONIZED ||
      (solutionInstance?.status === SOLUTION_INSTANCE_STATUS.ENABLED &&
        !solutionInstance?.isDeleted) ||
      hrisConnection?.hasIntegration
    );
  }

  installApplicationTask = task(async (model, app) => {
    let { organization } = this.organizationManager;
    let { hubApplication, hrisConnection } = model;

    if (app === 'slack') {
      this._installSlack(organization, hubApplication);
    } else {
      if (hrisConnection) {
        await hrisConnection.setup(() => {
          this.router.transitionTo(
            'settings.connect-hub.applications.hub-application.hris-success',
            organization.slug,
            app
          );
        });
      } else {
        await this.router.transitionTo(this.setupRouteName, organization.slug, app);
      }
    }
  });

  uninstallApplicationTask = task(async params => {
    let { organization } = this.organizationManager;
    let { hubApplication, hubConnection, hrisConnection, prismicData, solutionInstance } = params;
    let model = hubConnection || solutionInstance || hrisConnection;
    let result = await this._confirmationModal(prismicData.name);
    if (result === 'confirm') {
      try {
        await model.destroyRecord();

        this.toastFlashMessages.toastSuccess(
          this.intl.t('settings.connections.disconnect-toast.success', {
            connectionName: prismicData.name,
          })
        );

        if (hubConnection) {
          this.router.transitionTo(this.settingsRouteName, organization.slug, hubApplication.slug);
        }
      } catch {
        this.toastFlashMessages.toastError(
          this.intl.t('settings.connections.disconnect-toast.error', {
            connectionName: prismicData.name,
          })
        );
      }
    }
  });

  searchIntegrationsTask = restartableTask(
    waitFor(async (query, page, includeBeta) => {
      try {
        return await this.getIntegrations({ query, page, includeBeta });
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    })
  );

  getIntegrationsTask = task(
    waitFor(async params => {
      let { categoryId, stakeholderId, collectionSlug, includeBeta, page, onlyMostPopularBanks } =
        params;

      try {
        let integrations = await this.getIntegrations({
          categoryId,
          stakeholderId,
          collectionSlug,
          includeBeta,
          page,
        });

        let filteredResults = integrations.results;

        if (onlyMostPopularBanks) {
          let categories = await this.getAllCategories();
          let bankCategory = categories.find(category => category.slug === CATEGORIES.BANKS);
          let bankMarket = this.organizationManager.organization.legalCountry.toLowerCase();

          filteredResults = integrations.results.filter(integration => {
            if (integration.data.category?.id === bankCategory?.id) {
              return integration.data[`bank_is_popular_in_${bankMarket}`];
            } else {
              return true;
            }
          });
        }

        return { ...integrations, results: filteredResults };
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    })
  );

  highlightBankIntegrationsTask = task(async params => {
    let { categoryId, includeBeta } = params;

    try {
      return await this.getIntegrations({
        categoryId,
        includeBeta,
        bankMarket: this.organizationManager.organization.legalCountry.toLowerCase(),
        orderings: {
          field: 'my.connect_integrations.bank_is_popular',
        },
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  _installSlack(organization, hubApplication) {
    let authenticationUrl = this.router.urlFor(
      this.authRouteName,
      organization.slug,
      hubApplication.slug
    );
    let redirectURI = window.location.origin + authenticationUrl;
    let state = crypto.randomUUID();
    window.sessionStorage.setItem(SLACK_OAUTH_STATE_KEY, state);
    window.location.assign(
      `${hubApplication.params.auth_url}&state=${state}&redirect_uri=${encodeURIComponent(
        redirectURI
      )}`
    );
  }

  _confirmationModal(appName) {
    let tData = { connectionName: appName };
    return this.modals.open('popup/destructive', {
      title: this.intl.t('settings.connections.disconnect-modal.title', tData),
      description: this.intl.t('settings.connections.disconnect-modal.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('settings.connections.disconnect-modal.disconnect-cta'),
    });
  }

  transform(integration) {
    return {
      ...integration,
      data: {
        ...integration.data,
        category: this.flattenItem(integration.data.category),
        stakeholders: this.flattenList(integration.data.stakeholders, 'stakeholder'),
        types: this.flattenList(integration.data.types, 'type'),
      },
    };
  }

  flattenItem(item) {
    return item
      ? {
          id: item.id,
          name: item.data?.name[0].text,
          tags: item.tags,
          slug: item.slug,
        }
      : null;
  }

  flattenList(list, key) {
    return list.map(item =>
      key
        ? {
            id: item[key].id,
            name: item[key].data?.name[0].text,
            tags: item[key].tags,
            slug: item[key].slug,
          }
        : {
            id: item.id,
            name: item.data?.name[0].text,
            slug: item.data.slug,
          }
    );
  }

  transformBucket(bucket) {
    return {
      id: bucket.id,
      title: bucket.data?.title[0].text,
      lightLogo: bucket.data?.light_logo?.url,
      darkLogo: bucket.data?.dark_logo?.url,
      slug: bucket.data.slug,
      expireNewBadgeDate: bucket.data?.expire_new_badge_date,
      locations: bucket.data?.locations.map(location => location.location_that_display_the_bucket),
    };
  }
}
