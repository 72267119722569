import { DEBUG } from '@glimmer/env';

import * as Sentry from '@sentry/ember';
import _missingMessage from 'ember-intl/-private/utils/missing-message';

export default (...args) => {
  let originalMessage = _missingMessage(...args);
  if (DEBUG) {
    let message = `THIS IS A DEV/TEST ONLY ERROR - DO NOT RELY ON IT BECAUSE IT WON'T WORK IN PRODUCTION!\nSee https://gitlab.qonto.co/qonto/qonto-js/-/merge_requests/4869\n${originalMessage}`;
    throw new Error(message);
  } else {
    Sentry.captureException(new Error(originalMessage));
    return _missingMessage;
  }
};
