import CURRENCIES from 'qonto/constants/currencies';
import { STATUS as REQUEST_STATUS } from 'qonto/constants/requests';
import {
  BULK_TRANSFER_ATTRS,
  COMMON_TRANSFER_ATTRS,
  INSTANT_DECLINED_REASONS_RETRY_STATUS,
  MULTI_TRANSFER_ATTRS,
  PAYMENT_PURPOSE_TYPES,
  SEPA_TRACKING_SETTLEMENT_STATUS,
  SEPA_TRACKING_TRANSFER_TYPE,
  SPEND_LIMIT_DISCLAIMER_TYPES,
  SPEND_LIMIT_TYPES,
  STATUS,
  STRUCTURED_PAYMENT_PURPOSE_LIST,
  TRANSFER_ATTRS,
} from 'qonto/constants/transfers';

/**
 * Copies beneficiary information into a transfer object.
 *
 * @param {TransferModel} transfer - A transfer (or transfer request) model instance to copy data into.
 * @param {BeneficiaryModel} beneficiary - The beneficiary model instance from which to copy data.
 * @param {object} [options={}] - An options object.
 * @param {boolean} [options.forceCopy] - If true, force copying data from the beneficiary even if the transfer object already has a specific value defined.
 * @param {boolean} [options.isRequest] - If true, indicates that this transfer is a transfer request, and as a result additional attributes should be set.
 *
 * @returns {object} The transfer object with beneficiary data copied into it.
 */
export function copyBeneficiaryIntoTransfer(transfer, beneficiary, options = {}) {
  let { forceCopy, isRequest } = options;

  if (beneficiary.get('fx')) {
    transfer.set('localAmountCurrency', beneficiary.get('currency'));
  }

  let copy = attribute => {
    if (forceCopy || !transfer.get(attribute)) {
      transfer.set(attribute, beneficiary.get(attribute));
    }
  };

  TRANSFER_ATTRS.forEach(copy);

  transfer.set('originalBeneficiaryEmail', beneficiary.get('email'));

  if (isRequest) {
    transfer.beneficiaryId = transfer.get('beneficiary.id');
    transfer.beneficiaryName = beneficiary.name;
    transfer.beneficiaryIban = beneficiary.iban;
    transfer.beneficiaryBic = beneficiary.bic;
    transfer.beneficiaryEmail = beneficiary.email;
  }

  transfer.email = transfer.email?.trim();

  return transfer;
}

/**
 * Copies beneficiary labels into a transfer object.
 *
 * This function sets the `labels` property of a transfer object to the `labels`
 * property of a beneficiary object, effectively copying the labels from the
 * beneficiary to the transfer.
 *
 * @param {TransferModel} transfer - The transfer model instance to which labels will be copied.
 * @param {BeneficiaryModel} beneficiary - The beneficiary model instance from which labels will be copied.
 * @returns {void}
 */
export function copyBeneficiaryLabelsIntoTransfer(transfer, beneficiary) {
  transfer.set('labels', beneficiary.get('labels'));
}

/**
 * Copies beneficiary VAT information into a transfer object.
 *
 * This function sets the `vatRate` and `vatAmount` properties of a transfer object
 * based on the VAT information provided by a beneficiary object.
 *
 * @param {TransferModel} transfer - The transfer model instance to which VAT information will be copied.
 * @param {BeneficiaryModel} beneficiary - The beneficiary model instance from which VAT information will be copied.
 * @returns {void}
 */
export function copyBeneficiaryVatIntoTransfer(transfer, beneficiary) {
  let vatRate = beneficiary.get('vatRate');

  let vatAmount = null;

  if (vatRate > 0) {
    let amount = transfer.amount;
    vatAmount = calculateVatAmount(amount, vatRate);
  } else if (vatRate <= 0 || !vatRate) {
    vatRate = null;
  }

  transfer.setProperties({
    vatRate,
    vatAmount,
  });
}

/**
 * Prepares the transfer limits data based on the warnings and spend limits
 * received from the server in response of the transfer confirmation.
 *
 * @param {Array<string>} warnings - The warnings received from the server.
 * @param {Object} spendLimits - The spend limits object received from the server.
 * @returns {PreparedLimitsData} The prepared limits data.
 *
 * @typedef {Object} PreparedLimitsData
 * @property {boolean} isAboveLimits - Indicates whether the limits are above the allowed thresholds.
 * @property {?string} aboveLimitType - The type of limit that is above the threshold.
 * @property {?number} monthSpendings - The current month spendings value.
 * @property {?number} monthly - The monthly transfer limit value.
 * @property {?number} perTransfer - The per transfer limit value.
 *
 * @example
 * const warnings = ['insufficient_monthly_limits'];
 * const spendLimits = {
 *   current_month_spendings: { value: 800 },
 *   monthly_transfer_limit: { value: 1500 },
 *   per_transfer_limit: { value: 200 }
 * };
 * const result = prepareLimitsData(warnings, spendLimits);
 * // result:
 * // {
 * //   isAboveLimits: true,
 * //   aboveLimitType: 'monthly',
 * //   monthSpendings: 800,
 * //   monthly: 1500,
 * //   perTransfer: 200
 * // }
 */
export function prepareLimitsData(warnings = [], spendLimits = {}) {
  let isAbovePerTransferLimit = warnings.includes(
    SPEND_LIMIT_DISCLAIMER_TYPES.INSUFFICENT_PER_TRANSFER_LIMITS
  );
  let isAboveMonthlyTransferLimit = warnings.includes(
    SPEND_LIMIT_DISCLAIMER_TYPES.INSUFFICENT_MONTHLY_LIMITS
  );
  let isRecurringDeclined = warnings.includes(SPEND_LIMIT_DISCLAIMER_TYPES.RECURRING_DECLINED);
  let isAboveLimits = isAbovePerTransferLimit || isAboveMonthlyTransferLimit || isRecurringDeclined;

  let aboveLimitType = isAbovePerTransferLimit ? SPEND_LIMIT_TYPES.PER_TRANSFER : null;
  aboveLimitType ??= isAboveMonthlyTransferLimit ? SPEND_LIMIT_TYPES.MONTHLY : null;
  aboveLimitType ??= isRecurringDeclined ? SPEND_LIMIT_TYPES.RECURRING : null;

  return {
    isAboveLimits,
    aboveLimitType,
    monthSpendings: spendLimits.current_month_spendings?.value,
    monthly: spendLimits.monthly_transfer_limit?.value,
    perTransfer: spendLimits.per_transfer_limit?.value,
  };
}

/**
 * Maps the attributes of a Transfer or BulkTransfer instance to a new object representing a request,
 * combining them with an optional base object.
 *
 * @param {Object} transfer - The Transfer or BulkTransfer model instance containing the attributes to map.
 * @param {Object} [initObj={}] - An optional base object (immutable) to merge the attributes into.
 * @returns {Object} - The new object with the mapped attributes to use for a Request.
 * @private
 */
function mapTransferToRequestAttrs(transfer, initObj = {}) {
  let transferRequestAttrsMap = {
    bic: 'beneficiaryBic',
    email: 'beneficiaryEmail',
    name: 'beneficiaryName',
    iban: 'beneficiaryIban',
  };

  return Object.entries(transferRequestAttrsMap).reduce(
    (result, [transferAttrName, requestAttrName]) => {
      result[requestAttrName] = transfer[transferAttrName];
      return result;
    },
    { ...initObj }
  );
}

/**
 * Rewrites the simple attributes of a MultiTransfer instance into a new object,
 * combining them with an optional base object.
 *
 * @param {MultiTransferModel} multiTransfer - The MultiTransfer model instance containing the attributes to rewrite.
 * @param {Object} [initObj={}] - An optional base object (immutable) to merge the attributes into.
 * @returns {Object} The new object with the rewritten attributes.
 */
function rewriteMultiTransferSimpleAttributes(multiTransfer, initObj = {}) {
  let result = { ...initObj };

  return MULTI_TRANSFER_ATTRS.reduce((result, attrName) => {
    result[attrName] = multiTransfer[attrName];
    return result;
  }, result);
}

export function getRequestTransferOptions(transfer) {
  let options = COMMON_TRANSFER_ATTRS.reduce((result, attrName) => {
    result[attrName] = transfer[attrName];
    return result;
  }, {});

  return mapTransferToRequestAttrs(transfer, options);
}

export function getRequestMultiTransferOptions(multiTransfer) {
  let requestMultiTransferOptions = rewriteMultiTransferSimpleAttributes(multiTransfer);
  requestMultiTransferOptions['totalTransfersAmountCurrency'] = CURRENCIES.default;
  let attrsMap = {
    transfersAmount: 'totalTransfersAmount',
  };

  Object.keys(attrsMap).forEach(transferAttrName => {
    let requestAttrName = attrsMap[transferAttrName];
    requestMultiTransferOptions[requestAttrName] = multiTransfer[transferAttrName];
  });

  return requestMultiTransferOptions;
}

export function getRequestMultiTransferTransferOptions(bulkTransfer) {
  let requestMultiTransferTransferOptions = {
    amount: bulkTransfer.amount.toString(), // BE required from us to pass amount as string
    ...BULK_TRANSFER_ATTRS.reduce((result, attrName) => {
      result[attrName] = bulkTransfer[attrName];
      return result;
    }, {}),
    activityTag: bulkTransfer.activityTag || bulkTransfer.activityTagCode,
  };

  return mapTransferToRequestAttrs(bulkTransfer, requestMultiTransferTransferOptions);
}

/**
 * Reassigns attachments between source and destination Transfer instances.
 *
 * @param {TransferModel} source - The source Transfer model instance from which to copy the attachments.
 * @param {TransferModel} destination - The destination Transfer model instance to which the attachments should be added.
 * @returns {void}
 * @private
 */
export function reassignAttachmentsBetweenModels(source, destination) {
  if (source.attachments.length) {
    destination.attachments.content?.push(...source.attachments.content);
  }
}

/**
 * Returns the transfer type that can be used as a tracking attribute, based on the provided options.
 *
 * @param {Object} options - The options for determining the tracking transfer type.
 * @param {boolean} [options.isQontoBeneficiary=false] - Indicates if the transfer is to a Qonto beneficiary.
 * @param {boolean} [options.isTransferScheduled=false] - Indicates if the transfer is scheduled.
 * @param {boolean} [options.isTransferInstant=false] - Indicates if the transfer is instant.
 * @param {boolean} [options.isTransferRequest=false] - Indicates if the transfer is a request.
 * @returns {string} The transfer type.
 */
export function getTrackingTransferType({
  isQontoBeneficiary = false,
  isTransferScheduled = false,
  isTransferInstant = false,
  isTransferRequest = false,
}) {
  let {
    BOOK_TRANSFER_SCHEDULED,
    BOOK_TRANSFER,
    INSTANT,
    REGULAR_SEPA,
    REGULAR_SEPA_SCHEDULED,
    REQUEST_FALLBACK,
  } = SEPA_TRACKING_TRANSFER_TYPE;

  if (isTransferRequest) {
    return REQUEST_FALLBACK;
  }

  if (isQontoBeneficiary) {
    return isTransferScheduled ? BOOK_TRANSFER_SCHEDULED : BOOK_TRANSFER;
  }

  if (isTransferInstant) {
    return INSTANT;
  }

  return isTransferScheduled ? REGULAR_SEPA_SCHEDULED : REGULAR_SEPA;
}

/**
 * Returns the transfer status that can be used as a tracking attribute, based on the provided options.
 *
 * @param {Object} options - The options for determining the tracking transfer status.
 * @param {boolean} [options.isTransferSettled=false] - Indicates if the transfer is settled.
 * @param {boolean} [options.isWaitingForAMLCheck=false] - Indicates if the transfer is waiting for AML check.
 * @param {boolean} [options.isKYBPending=false] - Indicates if the transfer is pending KYB (Know Your Business) check.
 * @param {boolean} [options.isTransferTimeout=false] - Indicates if the transfer has timed out.
 * @param {boolean} [options.isTransferRequest=false] - Indicates if the transfer is a request.
 * @returns {string|null} The transfer status.
 */
export function getTrackingTransferStatus({
  isTransferSettled = false,
  isWaitingForAMLCheck = false,
  isKYBPending = false,
  isKYCPending = false,
  isTransferTimeout = false,
  isTransferRequest = false,
}) {
  let { PENDING_KYBC, PENDING_REVIEW, SUCCESS, TIMEOUT } = SEPA_TRACKING_SETTLEMENT_STATUS;

  if (isTransferRequest) {
    return SUCCESS;
  }

  if (isTransferTimeout) {
    return TIMEOUT;
  }

  if (isTransferSettled && isWaitingForAMLCheck) {
    return PENDING_REVIEW;
  }

  if (isTransferSettled && (isKYBPending || isKYCPending)) {
    return PENDING_KYBC;
  }

  if (isTransferSettled) {
    return SUCCESS;
  }

  return null;
}

/**
 * Creates a payload for the SEPA transfer settlement tracking event based on the provided parameters.
 *
 * @param {Object} options - The options for creating the payload.
 * @param {string} options.cta - The action used for the transfer success redirection.
 * @param {string} options.declinedReason - The reason for the declined instant transfer (if applicable).
 * @param {InvoiceModel} options.invoice - The invoice Transfer instance.
 * @param {boolean} options.isQontoBeneficiary - Indicates if the beneficiary is a Qonto beneficiary.
 * @param {string} options.origin - An arbitrary name used to determine on which part of the application the user was before creating a transfer.
 * @param {TransferModel} options.sepaTransfer - The SEPA Transfer instance.
 * @param {string} options.settlementStatus - The settlement status.
 * @param {string} options.isKYCPending - The KYC pending status.
 * @returns {Object} The payload for the SEPA settlement tracking event.
 */
export function createSepaSettlementTrackingEventPayload({
  cta,
  declinedReason,
  invoice,
  isQontoBeneficiary,
  origin,
  sepaTransfer,
  settlementStatus,
  isKYCPending,
}) {
  let isKYBPending = sepaTransfer.get('organization.kybPending');
  let isWaitingForAMLCheck =
    isQontoBeneficiary && sepaTransfer.isPending && !sepaTransfer.wasScheduled;

  let isTransferInstant = sepaTransfer.instant;
  let isTransferSettled = settlementStatus === STATUS.COMPLETED;
  let isTransferTimeout = settlementStatus === 'timeout';
  let isTransferDeclined = settlementStatus === STATUS.DECLINED;
  let isTransferScheduled = sepaTransfer.wasScheduled;
  let isTransferRequest = settlementStatus === REQUEST_STATUS.PENDING;

  let transferType = getTrackingTransferType({
    isQontoBeneficiary,
    isTransferScheduled,
    isTransferInstant,
    isTransferRequest,
  });

  let status = getTrackingTransferStatus({
    isTransferSettled,
    isWaitingForAMLCheck,
    isKYBPending,
    isKYCPending,
    isTransferTimeout,
    isTransferRequest,
  });

  return {
    transfer_type: transferType,
    transfer_success_redirection: cta,
    ...(invoice?.id && { flow: 'byinvoice' }),
    ...(origin && { origin }),
    ...(status && { status }),
    ...(isTransferDeclined && {
      fallback_available: canDeclinedInstantTransferBeRetried(declinedReason),
    }),
  };
}

/**
 * Checks if a declined instant transfer can be retried based on the declined reason.
 *
 * @param {string} declinedReason - The reason for the declined instant transfer.
 * @returns {boolean} true if the declined instant transfer can be retried, otherwise false.
 */
export function canDeclinedInstantTransferBeRetried(declinedReason) {
  let hasDefinedRetryStatus = INSTANT_DECLINED_REASONS_RETRY_STATUS.hasOwnProperty(declinedReason);
  return hasDefinedRetryStatus ? INSTANT_DECLINED_REASONS_RETRY_STATUS[declinedReason] : true;
}

/**
 * Calculate the VAT amount from a given amount and VAT rate.
 *
 * @param {number} amount - The original amount.
 * @param {number} vatRate - The VAT rate as a percentage.
 * @returns {string} The VAT amount formatted as a string with two decimal places.
 *
 * @example
 * // Calculate VAT amount for an amount of 120 with a VAT rate of 20%
 * const amount = 120;
 * const vatRate = 20;
 * const vatAmount = calculateVatAmount(amount, vatRate);
 * console.log(vatAmount); // Outputs: "20.00"
 */
export function calculateVatAmount(amount, vatRate) {
  let taxInclusiveAmount = 1 + vatRate / 100;
  let taxExclusiveAmount = amount / taxInclusiveAmount;
  let vatAmount = (taxExclusiveAmount - amount) * -1;
  return vatAmount.toFixed(2);
}

/**
 * Returns a purpose of payment (PoP) label to be shown to the user.
 *
 * @param {Object} options - The object containing the PoP data.
 * @param {string} options.code - The purpose of payment code.
 * @param {string} options.country - The country for which the PoP is being retrieved.
 * @param {string} options.currency - The currency associated with the payment.
 * @param {Object} options.intlService - The internationalization service for translations.
 * @returns {string} The PoP label to be displayed.
 *
 * @example
 * const label = getPaymentPurposeLabel({ code: 'CGODDR', country: 'CN', currency: 'CNY', intlService: i18n });
 */
export function getPaymentPurposeLabel({ code = '', country = '', currency = '', intlService }) {
  let label = '';

  switch (country) {
    case 'JO':
    case 'BH':
    case 'AE':
      label = intlService.t(STRUCTURED_PAYMENT_PURPOSE_LIST[country][code]);
      break;
    case 'CN':
      label =
        currency === 'CNY' ? intlService.t(STRUCTURED_PAYMENT_PURPOSE_LIST[country][code]) : code;
      break;
    default:
      label = code;
      break;
  }

  return label;
}

/**
 * Determines the payment purpose type based on the provided codes.
 *
 * @param {Array|string|undefined} codes - The payment purpose codes.
 * @returns {string} The payment purpose type. Possible values are:
 *   - "none" for when no codes are provided.
 *   - "unstructured" for when an empty array is provided.
 *   - "structured" for when a non-empty array of codes is provided.
 */
export function getPaymentPurposeType(codes) {
  if (!codes) {
    return PAYMENT_PURPOSE_TYPES.NONE;
  } else if (Array.isArray(codes) && codes.length) {
    return PAYMENT_PURPOSE_TYPES.STRUCTURED;
  } else {
    return PAYMENT_PURPOSE_TYPES.UNSTRUCTURED;
  }
}

export default {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
  getRequestMultiTransferOptions,
  getRequestMultiTransferTransferOptions,
  getRequestTransferOptions,
  prepareLimitsData,
  reassignAttachmentsBetweenModels,
  getPaymentPurposeLabel,
  getPaymentPurposeType,
};
